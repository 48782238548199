<template>
  <div>
    <div class="full-width">
      <h3>{{label}}</h3>
      <v-text-field hide-details v-model="inputVal" dense outlined label="Enter the skill" class="mt-3">
        <v-btn x-small outlined color="indigo" slot="append" @click="this.onAdd"> click to add</v-btn>
      </v-text-field>
      <div class="mt-2">
        <p class="small text-grey" v-if="this.list.length == 0">Your list for <b>{{this.label}}</b> is empty. Start adding skills by typing in the text field above and click add</p>
        <div class="skill-entry" v-for="item, index in this.list" :key="index">
          <v-btn fab text x-small color="red" class="mr-2" @click="onDelete(item)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          {{item}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
  },
  name: 'Alert',
  props: {
    label: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },    
    onAdd: {
      type: Function,
      default: () =>null,
    },
    onDelete: {
      type: Function,
      default: () =>null,
    },
    value:{},
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.skill-entry {
  border: 1px solid #b19cf1;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  border-left-width: 5px;
}
</style>