<template>
  <div :class="{'is-mobile':isMobile()}">
    <v-alert
      color="green darken-2"
      border="left"
      text
      dense
      type="success"
      :icon="this.icon ? this.icon : 'mdi-check-circle'"
      v-if="this.textSuccess && this.success === true"
    >
      <v-row align="center">
        <v-col > 
          <span v-html="this.textSuccess" class="mb-4"/>
          <v-btn v-if="!!this.actionTextSuccess"  class="action-button py-2 ml-2" outlined  small color="green darken-2" @click="this.actionMethodSuccess">{{this.actionTextSuccess}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      color="red darken-2"
      border="left"
      text
      dense
      :icon="this.icon ? this.icon : 'mdi-alert-circle-outline' "
      type="error"
      v-if="this.textError && this.success === false"
    >
      <v-row align="center">
        <v-col class="grow">
          <span v-html="this.textError" class="mb-4"/>
          <v-btn v-if="!!this.actionTextError" small class="action-button py-2 ml-2" outlined color="red darken-2" @click="this.actionMethodError">{{this.actionTextError}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
export default {
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  name: 'Alert',
  props: {
    textSuccess: {
      type: String,
      default: '',
    },
    textError: {
      type: String,
      default: '',
    }, 
    icon: {
      type: String,
      default: null,
    },    
    actionTextSuccess: {
      type: String,
      default: '',
    },
    actionTextError: {
      type: String,
      default: '',
    },
    actionMethodSuccess: {
      type: Function,
      default: () =>null,
    },
    actionMethodError: {
      type: Function,
      default: () =>null,
    },
    success: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped lang="scss">
  .label{
    color:rgb(33, 33, 117);
    font-weight:bold !important;
  }
  .is-mobile {
   .action-button , .action-button  span{
      display: block;
      max-width: 200px;
      width: 200px;
      text-wrap: wrap;
      height: auto !important;
    } 
  }
  .action-button {
    float: right;
  }
  .is-mobile {
   .action-button {
    margin-top: 1rem;
    float: inherit;
   }
  }
</style>