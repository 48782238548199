<template>
  <v-container class="px-10">
    <h1>T-Shirts</h1>
    <div v-for="regFor, index in Object.keys(this.teeSizes)" :key="index">
      <h2>{{regFor}}</h2>
      <p>
        <span v-for="qty,size in teeSizes[regFor].counts" :key="size">
          {{size}} - {{qty}} | 
        </span>
      </p>
    </div>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {},
  data() {
    return {
      athletes: [],
      leoSizes: [
        'CSM',
        'INT',
        'CME',
        'CLA',
        'AXS',
        'ASM',
        'AME',
        'ALA',
        'AXL',
        'A2XL'
      ],
      teeSizes: [],
    }
  },
  async mounted() {
    this.athletes = (await api.post("/athlete")).data?.data|| [];
    this.tshirts();
  },
  computed: {
  },
  methods: {
    tshirts(){
      const athletes = this.athletes;
      const athletesFiltered = athletes.filter((item)=> !!item.deposit && !!item.athleteTeeSize)
      const results = {total: {}};
      for(let i = 0; i < athletesFiltered.length; i += 1) {
        const {gymName, athleteGradYear, athleteFirstName, athleteLastName, athleteTeeSize, athleteRegisteredFor} = athletesFiltered[i]
        if(!Object.prototype.hasOwnProperty.call(results,athleteRegisteredFor)){
          results[athleteRegisteredFor] = {counts: {}, athletes: []};
        }
        if(!Object.prototype.hasOwnProperty.call( results[athleteRegisteredFor].counts,athleteTeeSize)){
          results[athleteRegisteredFor].counts[athleteTeeSize] = 0;
        }
        if(!Object.prototype.hasOwnProperty.call( results.total,athleteTeeSize)){
          results.total[athleteTeeSize] = 0;
        }
        results[athleteRegisteredFor].athletes.push({gymName, athleteRegisteredFor, athleteGradYear, athleteFirstName, athleteLastName, athleteTeeSize});
        results[athleteRegisteredFor].counts[athleteTeeSize] += 1;
        results.total[athleteTeeSize] += 1;
      }
      this.teeSizes = results;
      return results;
    }
  },
}
</script>

<style lang="scss">

</style>