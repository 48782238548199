







































import Vue from "vue";
//@ts-ignore
export default Vue.extend({
  name: "SuggestedSkills",
  components: {
  },
  data: () => ({}),
  computed: {},
  methods: {},
});
