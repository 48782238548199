<template>
  <v-container class="px-10">
      <simple-back-button/>
      <h1 class="full-width text-center">Waiver Preview</h1>
      <div class="full-width text-center">
        <v-btn color="info" outlined class="mb-4" :href="this.waiverImage" target="_blank"> Click here if you do not see your waiver image below</v-btn>
        <img id="waiver-image" v-if="this.athlete.waiver_signed_id" :src="this.waiverImage" class="waiver-picture"/>
        <alert 
          :success="!!this.athlete.waiver_signed_id === true"
          :textSuccess="`You have signed this waiver for <b>${this.athlete.athleteFirstName}</b> on ${this.athlete.waiver_signed_on}`"
        />
      </div>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import Alert from "../../components/Alert.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {Alert, SimpleBackButton},
  data() {
    return {
      athlete: [],
    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
  },
  methods: {
  },
  computed: {
      waiverImage: function() {

          return `https://lh3.googleusercontent.com/d/${this.athlete.waiver_signed_id}=w1000?authuser=1/view`
      }
  },
}
</script>

<style lang="scss">
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }

  .waiver-picture {
    width:90%;
    border-radius: 16px;
    border: 4px solid #DDD;
    overflow: hidden;
  }
</style>