<template>
  <v-container class="px-10">
      <simple-back-button/>
      <h1 class="full-width text-center">Profile Picture</h1>
      <div class="full-width text-center">
        <img v-if="this.athlete.profile_picture_id" :src="profileImage" class="profile-picture"/>
        <alert 
          :success="!!this.athlete.profile_picture_id === true"
          :textSuccess="`You have already uploaded a profile picture for <b>${this.athlete.athleteFirstName}</b> but you can still change it by following the steps below`"
          :textError="`You have not yet uploaded a profile picture for <b>${this.athlete.athleteFirstName}</b>`"
        />
      </div>
      
      <p class="full-width text-center small mt-10"><b>Do not upload pictures of your gymnast in action. Coaches will have a chance to see their gymnastics in person.</b> <br/> To make it easier for college coaches to identify your gymnast, please upload a passport style picture. Preferably taken on a mobile device in portrait mode in a well illuminated environment. Framed as close as possible to the example below </p>
      <div class="full-width text-center">
        <img src="mobile-picture.png" class="mobile-picture"/>
      </div>
      <v-form class="full-width text-center" v-model="valid" ref="form">
        <v-file-input
          class="mt-4 file-input"
          outlined
          :rules="rules"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Pick an avatar"
          prepend-icon="mdi-camera"
          label="Click here to upload"
          v-model="file"
        />
        <v-btn class="mt-1 file-input mb-10" color="info" :disabled="!this.file || !this.valid" elevation="0" @click="validate">Upload Photo</v-btn>

      </v-form>

  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import Alert from "../../components/Alert.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {Alert,SimpleBackButton},
  data() {
    return {
      athlete: [],
      rules: [
        value => !value || value.size < 6000000 || 'Avatar size should be less than 6 MB!',
      ],
      file: null,
      valid: false
    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
  },
  methods: {
    async validate() {
     const v = this.$refs.form.validate();

     if (v) {
      const payload = {};
      const fd = new FormData();
      const fileName = `${this.athlete.gymName}-${this.athlete.athleteFirstName} ${this.athlete.athleteLastName}`.replace(/\s/g, '_').toLowerCase();
      fd.append('file', this.file)
      fd.append("id", this.athlete._id);
      fd.append("fileName", fileName);
      this.athlete = (await api.post("/upload-profile-picture", fd)).data?.data?.[0] || {};
      this.file = null
     }
    }
  },
  computed: {
      profileImage: function() {
          // return `https://drive.google.com/uc?export=view&id=${this.athlete.profile_picture_id}`
          // return `http://drive.usercontent.google.com/download?id=${this.athlete.profile_picture_id}&export=view&authuser=0`
          return `https://lh3.googleusercontent.com/d/${this.athlete.profile_picture_id}=w1000?authuser=1/view`
      }
  },
}
</script>

<style lang="scss">
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }
  .mobile-picture {
    width: 150px;
    border-radius: 16px;
    border: 2px solid #CCC;
    overflow: hidden;
  }
  .file-input {
    max-width: 320px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .profile-picture {
    width:200px;
    border-radius: 16px;
    border: 4px solid #DDD;
    overflow: hidden;
  }
  .profile-picture-message {
    width:320px;
        margin-left: auto !important;
    margin-right: auto !important;
  }
</style>