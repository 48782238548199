<template>
  <v-row class="pt-3 pb-13">
    <v-btn color="info" text small @click="$router.push(to ? to : `./regDetails?id=${id}`)" v-if="id">
      <v-icon>mdi-chevron-left</v-icon> {{`${this.label ? this.label : 'Back to registration details'}`}}
    </v-btn>
  </v-row>
</template>
<script>
export default {
  components: { },
  props: {
    label: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''      
    }
  },
  data() {
    return {
      id:''
    }
  },
  async mounted() {
    this.id = this.$route.query.id;
  },
}
</script>