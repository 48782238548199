<template>
  <v-container class="px-10">
    <v-row>
      <v-col>
        <h1>Apex College Showcase Participants </h1>
        <p>Out of <b>{{registered}}</b> participants registered, <b>{{approved.approved}}</b> were approved ({{approved.gymnastics}} gymnastics, {{approved.acro}} aco, {{approved.both}} Gym and Acro, {{approved.a_tPlusAllLevels}} Acro & All Levels  and {{approved.all}} all levels) and <b>{{denied.denied}}</b> were denied ({{denied.gymnastics}} gymnastics, {{denied.acro}} aco, {{denied.both}} Gym and Acro, {{approved.a_tPlusAllLevels}} Acro & All Levels and {{denied.all}} all levels). <b>{{deposited}}</b> paid their deposit and <b>{{inFull}}</b> paid in full</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="athlete"
          item-value="_id"
          :items-per-page="100"
          class="elevation-0"
          :search="search"
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          v-model="selectedRows"
          show-select
          item-key="_id"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              outlined
              clearable
              label="Search any field"
              hide-details
            ></v-text-field>
            <div class="x-small mt-1"><b>You can also search for:</b> <v-btn x-small dense text @click="setSearch('skills completed')" color="info">skills completed</v-btn> |  <v-btn x-small dense text @click="setSearch('skills not completed')" color="info">skills not completed</v-btn> | <v-btn x-small dense text @click="setSearch('deposit paid')" color="info">deposit paid</v-btn> | <v-btn x-small dense text @click="setSearch('deposit not paid')" color="info">deposit not paid</v-btn> | <v-btn x-small dense text @click="setSearch('full payment paid')" color="info">full payment paid</v-btn> | <v-btn x-small dense text @click="setSearch('full payment not paid')" color="info">full payment not paid</v-btn> | <v-btn x-small dense text @click="setSearch('waiver signed')" color="info">waiver signed</v-btn> | <v-btn x-small dense text @click="setSearch('waiver not signed')" color="info">waiver not signed</v-btn></div>
          </template>
          <template v-slot:item="row">
              <tr :class="{'highlighted': row.index % 2 == 0, 'not-highlighted': row.index % 2 != 0  }">
                <td class="text-center align-center">
                  <v-checkbox
                    hide-details
                    :value="findInSelectedRows(row.item) > -1"
                    @click="toggle(row.item)"/>
                </td>
                <td class="text-center align-center">{{row.item.athleteGradYear}}</td>
                <td>
                  {{row.item.athleteFirstName}} {{row.item.athleteLastName}}</td>
                <td>{{row.item.gymName}}</td>
                <td class="text-center align-center">
                  <v-select class="session-select" hide-details :items="sessions" v-model="row.item.athleteSession"  outlined dense x-small @change="updateSession(row.item)"></v-select>
                </td>
                <td>
                  <div class="x-small px-2">
                    {{row.item.athleteRegisteredFor}}
                  </div>
                  <div>
                    <div v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('gymnastics hp showcase') > -1">
                      T-shirt: {{row.item.athleteTeeSize}} | Leo: {{row.item.athleteLeoSize}}
                    </div>
                    <div v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('acro & tumbling') > -1">
                      T-shirt: {{row.item.athleteTeeSize}} | Tank: {{row.item.athleteTankSize}} | short: {{row.item.athleteShortSize}}
                    </div>
                    <div v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('both - gym hp & a&t') > -1 || row.item.athleteRegisteredFor.toLowerCase().indexOf('both - acro & tumbling plus all levels') > -1">
                      T-shirt: {{row.item.athleteTeeSize}} | Leo: {{row.item.athleteLeoSize}} | Tank: {{row.item.athleteTankSize}} | Short: {{row.item.athleteShortSize}}
                    </div>
                    <div v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('all levels') > -1">
                      T-shirt: {{row.item.athleteTeeSize}} 
                    </div>

                    <div v-if="row.item.athleteRegisteredFor.toLowerCase().indexOf('apex high performance camp') > -1" class="small px-2">
                      Leo: {{row.item.athleteLeoSize}}
                    </div>
                  </div>
                </td>

                <td>
                  <div class="mb-1 x-small" v-if="row.item.deposit">Deposit Paid</div>
                  <div class="mb-1 x-small" v-if="!row.item.deposit">Deposit Not Paid</div>
                  <v-btn v-if="!row.item.deposit" dark x-small class="action-btn" color="green" @click="deposit(row.item._id, true)" left outlined>
                    <v-icon   dark small >mdi-cash-100</v-icon> 
                    Make Deposit
                  </v-btn>
                  <v-btn v-if="row.item.deposit" dark x-small class="action-btn" color="red" @click="deposit(row.item._id, false)" left outlined>
                    <v-icon dark small >mdi-cash-100</v-icon> 
                    Revert Deposit
                  </v-btn>
                </td>
                <td>
                  <div class="mb-1 x-small" v-if="row.item.fullPayment">Paid in full</div>
                  <div class="mb-1 x-small" v-if="!row.item.fullPayment">Not Paid in full</div>
                  <v-btn dark  v-if="!row.item.fullPayment" x-small class="action-btn"  color="green"  @click="fullPayment(row.item._id, true, row.item.deposit)" left outlined>
                      Make Full pmt
                  </v-btn>
                  <v-btn v-if="row.item.fullPayment" dark x-small class="action-btn"  color="red"  @click="fullPayment(row.item._id, false)" left outlined>
                      Revert Full pmt
                  </v-btn>  
                </td>
                <td>
                  <div class="mb-1 x-small" v-if="!row.item.approved">Denied</div>
                  <v-btn v-if="!row.item.approved" dark x-small class="action-btn"  color="green"  @click="approveDeny(row.item._id, true)" left outlined>
                      <v-icon dark small >mdi-check </v-icon>
                      Approve
                  </v-btn>
                  <div class="mb-1 x-small" v-if="row.item.approved">Approved</div>
                    <v-btn v-if="row.item.approved" dark x-small class="action-btn"  color="red" @click="approveDeny(row.item._id, false)" left outlined>
                      <v-icon dark small >mdi-close </v-icon>
                      Deny
                  </v-btn>
                </td>
                <td>
                  <br/>           
                  <v-btn dark x-small class="action-btn" color="blue" @click="() => gotoEdit(row.item._id)" left outlined>
                    Edit
                  </v-btn>
                </td>
              </tr>
              <tr :class="{'expanded-region': true, 'highlighted': row.index % 2 == 0, 'not-highlighted': row.index % 2 != 0  }">
              <td colspan="10">
              <v-btn v-if="!row.item.waiver_signed" dark x-small class="action-btn" color="red" left text>
                  <v-icon small>mdi-close</v-icon> Waiver not signed {{row.id}}
              </v-btn>
              <v-btn v-if="row.item.waiver_signed" dark x-small class="action-btn" color="green" left text>
                  <v-icon small>mdi-check</v-icon> Waiver signed
              </v-btn>
              <v-btn v-if="!row.item.profile_picture_id === true" dark x-small class="action-btn" color="red" left text>
                  <v-icon small>mdi-close</v-icon> Picture not uploaded
              </v-btn>
              <v-btn v-if="!!row.item.profile_picture_id === true" dark x-small class="action-btn" color="green"  left text>
                  <v-icon small>mdi-check</v-icon> Picture Uploaded
              </v-btn>
              <v-btn dark x-small v-if="!isProfileComplete(row.item)" class="action-btn" color="red" left text>
                <v-icon small>mdi-close</v-icon> Skills not Completed
              </v-btn>
              <v-btn dark x-small v-if="isProfileComplete(row.item)" class="action-btn" color="green"  left text>
                <v-icon small>mdi-check</v-icon> Skills Completed
              </v-btn>

            </td>
              </tr>
          </template>

          <template v-slot:footer.prepend>
            <v-btn color="info" elevation="0" :disabled="selectedRows.length == 0" @click="dialog = true">Get emails for {{selectedRows.length}} selected rows </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
          Which emails do you want to collect?
        </v-card-title>

        <v-card-text>
          <v-checkbox label="Parents emails" hide-details v-model="copyParentEmail"/>
          <v-checkbox label="Coaches emails" hide-details v-model="copyCoachEmail"/>
          <v-checkbox label="Gymnasts emails" hide-details v-model="copyGymnastEmail"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="gray darken-1" text @click="dialog = false">
            Cancel
          </v-btn>

          <v-btn color="primary darken-1" @click="copyEmailsToClipboard()">
            Copy to clipboard
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :multiline="true" :timeout="5000" color="success">
      Emails copied to clipboard successfuly, use CRTL+V to paste them where you intend to use them
    </v-snackbar>

  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {},
  data() {
    return {
      athlete: [],
      sessions: [
        "Session 1",
        "Session 2",
        "Session 3",
        "Session 4",
        "Session 1 & 2",
        "Session 1 & 3",
        "Session 1 & 4",
        "Session 1 , 2 & 4",
        "Session 1 , 3 & 4",
      ],
      search:'',
      expanded: [0],
      selectedRows:[],
      copyParentEmail: false, 
      copyGymnastEmail: false, 
      copyCoachEmail: false, 
      dialog: false,
      snackbar: false,
      singleExpand: false,
        headers:[
        {
          text: 'Grad Year',
          align: 'center',
          value: 'athleteGradYear',
          width:'100px',
        },
        {
          text: 'First Name',
          align: 'start',
          value: 'athleteFirstName',
        },
        {
          text: 'Last Name',
          align: ' d-none',
          value: 'athleteLastName',
        },
        {
          text: 'Waiver Signed',
          align: ' d-none',
        },
        {
          text: 'Gym',
          align: 'start',
          value: 'gymName',
        },
        {
          text: 'Session',
          value: 'athleteSession',
        },
        {
          text: 'Registered for',
          value: 'athleteRegisteredFor',
        },
        {
          text: 'Deposit',
          align: 'start',
          value: 'deposit',
        },
        {
          text: 'Full Pmt',
          align: 'start',
          value: 'fullPayment',
        },
        {
          text: 'Status',
          align: 'start',
          value: "approved"
        },
        {
          text: 'Action',
          align: 'start',
        },
        { text: '', value: 'data-table-expand' },
        {
          text: 'Dep',
          value: "fullPaymentText",
          align: ' d-none'
        },
        {
          text: 'full',
          value: "depositText",
          align: ' d-none'
        },
        {
          text: 'waiver',
          align: ' d-none',
          value: "waiverText"
        },        
        {
          text: 'skills',
          align: ' d-none',
          value: "skillsCompleteText"
        },        
      ],

    }
  },
  async mounted() {
    await this.getAthletes();
  },
  methods: {
    setSearch(val) {
      this.search = val;
    },
    findInSelectedRows(val){
      if(this.selectedRows.length > 0) {
        for(let i = 0; i < this.selectedRows.length; i += 1) {
          if(this.selectedRows[i]._id === val._id) {
            return i;
          }
        }
      return -1;
      } else {
        return -1;
      }
    },
    addToSelectedRows(val) {
      const exists = this.findInSelectedRows(val);
      if(exists >= 0) return;
      this.selectedRows.push(val);
    },
    deleteFromSelectedRows(val) {
      const exists = this.findInSelectedRows(val);
      if(exists < 0) return;
      this.selectedRows.splice(exists, 1);
    },
    toggle(val) {
      const exists = this.findInSelectedRows(val);
      if(exists > -1) {
        this.deleteFromSelectedRows(val);
      } else {
        this.addToSelectedRows(val);
      }
    },
    copyEmailsToClipboard(){
      let emails = "";
      const separator = ";";
      const lineFeed = "\n";
      for (let i = 0 ; i < this.selectedRows.length ; i += 1) {
        if(this.copyParentEmail) {
          emails += `"${this.selectedRows[i].parentFirstName} ${this.selectedRows[i].parentLastName} (PARENT) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].parentEmail}>${separator}${lineFeed}`
        }

        if(this.copyCoachEmail) {
          emails += `"${this.selectedRows[i].coachFirstName} ${this.selectedRows[i].coachLastName} (COACH) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].coachEmail}>${separator}${lineFeed}`
        }


        if(this.copyGymnastEmail) {
          emails += `"${this.selectedRows[i].athleteFirstName} ${this.selectedRows[i].athleteLastName} (ATHLETE) - ${this.selectedRows[i].gymName}"<${this.selectedRows[i].athleteEmail}>${separator}${lineFeed}`
        }
      }

      this.copyTextToClipboard(emails)
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        if(successful == 'successful') {
          this.snackbar = true;
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      const that = this;
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
        that.snackbar = true;
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    async approveDeny(id, val) {
      await api.post("/approveDenyAthlete", {id, keyValue: {approved: val, approvedDeniedOn: (new Date())} });
      await this.getAthletes();
    },
    async fullPayment(id, val, dep) {
      let deposit = {};
      if(dep === false) {
        deposit = {
          deposit: true,
          depositOn: (new Date())
        }
      }
      await api.post("/fullPayment", {id, keyValue: {fullPayment: val, fullPaymentOn: (new Date()), ...deposit} });
      await this.getAthletes();
    },
    async deposit(id, val) {
      await api.post("/depositPayment", {id, keyValue: {deposit: val, depositOn: (new Date())} });
      await this.getAthletes();
    },
    gotoEdit(id){
      this.$router.push(`/regDetails?id=${id}`);
    },
    async getAthletes() {
      const {id} = this.$route.query;
        this.athlete = (await api.post("/athlete",{keyValue: {athleteRegisteredFor: 'Apex High Performance Camp 2024 ($425)'}})).data?.data|| [];
        for(let i = 0; i < this.athlete.length; i += 1){
          this.athlete[i].waiverText = this.athlete[i].waiver_signed ? "waiver signed" : "waiver not signed";
          this.athlete[i].depositText = this.athlete[i].deposit ? "deposit paid" : "deposit not paid";
          this.athlete[i].fullPaymentText = this.athlete[i].fullPayment ? "full payment paid" : "full payment not paid";
          this.athlete[i].skillsCompleteText = this.isProfileComplete(this.athlete[i]) ? "Skills completed" : "Skills not completed";
        }
    },
    isProfileComplete(item) {
      const events = ['vt_current','vt_working','ub_current','ub_working','bb_current','bb_working','fx_current','fx_working']
      let complete = true;
      if (item) {
        for (let i = 0; i < events.length; i++) {
          complete = complete && item[events[i]]?.length > 0;
        }
      }
      return complete && !!item.athleteInstagram && !!item.athleteYoutube && !!item.athleteGymStyle;
    },
    addSkill(key, val) { 
      return async () => {
        const index =  this.athlete[key].indexOf(val);
        if(index < 0) {
          const keyValue = {};
          this.athlete[key].push(val);
          keyValue[key] = this.athlete[key];
          console.log(keyValue)
          this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
        }
        this[key] = "";
      }
    },
    deleteSkill(key) {
      return async (item) => {
        const index =  this.athlete[key].indexOf(item);
        if (index > -1) { // only splice array when item is found
          this.athlete[key].splice(index, 1); // 2nd parameter means remove one item only
          const keyValue = {};
          keyValue[key] = this.athlete[key];
          this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
        }
      }
    },
    async saveChanges(keyValue) {
                this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
    },
    async updateSession(athlete) {
      const keyValue = {};
      keyValue['athleteSession'] = athlete.athleteSession;
      await api.post("/updateProfile", {id: athlete._id, keyValue});
      this.getAthletes();
    }
  },
  computed: {
      isChanged: function(original, newValue) {
        return () => newValue !== original;
      },
      waiverImage: function() {
          return `https://lh3.googleusercontent.com/d/${this.athlete.waiver_signed_id}=w1000?authuser=1/view`
      },
      registered: function() {
        return this.athlete.length;
      },
      approved: function() {
        const filtered = this.athlete.filter((item) => item.approved);
        return {
          approved: filtered.length,
          gymnastics: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Gymnastics HP Showcase") > -1).length,
          acro: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Acro & Tumbling") > -1).length,
          both: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Both - Gym HP & A&T") > -1).length,
          a_tPlusAllLevels: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Both - Acro & Tumbling plus All Levels") > -1).length,
          all: filtered.filter((item) => item.athleteRegisteredFor.indexOf("All Levels") > -1).length
        }
      },
      denied: function() {
        const filtered = this.athlete.filter((item) => !item.approved);
        return {
          denied: filtered.length,
          gymnastics: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Gymnastics HP Showcase") > -1).length,
          acro: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Acro & Tumbling") > -1).length,
          a_tPlusAllLevels: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Both - Acro & Tumbling plus All Levels") > -1).length,
          both: filtered.filter((item) => item.athleteRegisteredFor.indexOf("Both - Gym HP & A&T") > -1).length,
          all: filtered.filter((item) => item.athleteRegisteredFor.indexOf("All Levels") > -1).length
        }

      },
      deposited: function() {
        return this.athlete.filter((item) => item.deposit).length;
      },
      inFull: function() {
        return this.athlete.filter((item) => item.fullPayment).length;
      }

  },
}
</script>

<style lang="scss">
.highlighted{
  background-color: #EEE;
  &:hover {
    background-color: #EEE !important;
  }
}
.session-select {
  max-width: 150px;
  
}

.not-highlighted{
  background-color: transparent;
  &:hover {
    background-color: transparent !important;
  }
}
  .action-btn {
    margin-right: .5rem;
    margin-bottom: .4rem;
  }
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }

  .waiver-picture {
    width:90%;
    border-radius: 16px;
    border: 4px solid #DDD;
    overflow: hidden;
  }
  .offset-label .v-input .v-label--active{
    transform: translateX(-20%) translateX(-7.5px) translateY(-24px) scale(0.75) !important;
  }
  .x-small, .x-small .v-btn.v-size--x-small {
    font-size: 9px !important;
  }
  .v-data-table__wrapper {
    tr {
      padding-bottom: 1rem;
      td {
        padding:.5rem 0 .5rem 0!important;
        border-bottom: none !important;
        border-top: thin solid #DDD;
        
      }
    }
  }
  .expanded-region {
    td {
      border-top: none !important;
    }
  }
  .text-center .v-input__slot {
    justify-content: center;
  }
</style>