<template>
  <v-container class="px-10">
      <simple-back-button to="/collegeshowcase/participants2024" label="back to list of athletes"/>
        <div class="display-flex mb-4">
          <div :class="`grad-year y${this.athlete.athleteGradYear}`">{{this.athlete.athleteGradYear}}</div>
          <div class="athlete-full-name"> 
            <div>
              {{this.athlete.athleteFirstName}} {{this.athlete.athleteLastName}} 
              <v-btn color="blue" class="contact-card" outlined small dense @click="makeVCard">Save Contact</v-btn>
            </div>
            <div class="committed-pill committed" v-if="this.athlete.athleteCommitted">
              Committed <span v-if="!!this.athlete.athleteCommittedTo"> to {{this.athlete.athleteCommittedTo}}</span>
            </div>
            <div class="committed-pill uncommitted" v-else>
              Uncommitted
            </div>
          </div>
        </div>
        <v-row >
        <v-col cols="12" xs="12" sm="3">
          <img v-if="this.athlete.profile_picture_id" :src="profileImage" class="official-profile-picture"/>
        </v-col>

        <v-col cols="12" xs="12" sm="4" >
          <v-card elevation="0" outlined>
            <v-card-text>
              <label-data-field label="Gym Name" :text="this.athlete.gymName"/>
              <label-data-field label="Level" :text="this.athlete.gymName"/>
              <label-data-field label="Athlete's Name" :text="`${this.athlete.athleteFirstName} ${this.athlete.athleteLastName}`"/>
              <label-data-field label="Date of Birth" :text="this.athlete.athleteDob"/>
              <label-data-field label="Gymnast's Email" :text="this.athlete.athleteEmail"/>
              <div class="mt-4">
                <v-icon>mdi-web</v-icon> GymStyle: {{this.athlete.athleteGymStyle}}
              </div>
              <div class="mt-4">
                <v-icon>mdi-instagram</v-icon> Instagram: {{this.athlete.athleteInstagram}}
              </div>
              <div class="mt-4">
                <v-icon>mdi-youtube</v-icon> GymStyle: {{this.athlete.athleteYoutube}}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="5" >
          <v-card elevation="0" outlined>
            <v-card-text>
              <h2 class="mb-2">Parent info</h2>
              <div><v-icon class="mr-2">mdi-account-heart</v-icon> {{this.athlete.parentFirstName}} {{this.athlete.parentLastName}}</div>
              <div><v-icon class="mr-2">mdi-phone-classic</v-icon> {{this.athlete.parentPhoneNumber}}</div>
              <div><v-icon class="mr-2">mdi-email-fast</v-icon> {{this.athlete.parentEmail}}</div>
              <h2 class="mt-4 mb-2">Coach info</h2>
              <div><v-icon class="mr-2">mdi-clipboard-account</v-icon> {{this.athlete.coachFirstName}} {{this.athlete.coachLastName}}</div>
              <div><v-icon class="mr-2">mdi-phone-classic</v-icon> {{this.athlete.coachPhoneNumber}}</div>
              <div><v-icon class="mr-2">mdi-email-fast</v-icon> {{this.athlete.coachEmail}}</div>
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    <hr class="my-8"/>
    <h2 class="mb-2">Skills</h2>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Vault Competed Skills" v-model="vt_current" :list="this.athlete.vt_current"/>
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Vault Working on" v-model="vt_working" :list="this.athlete.vt_working"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Bars Competed Skills" v-model="ub_current" :list="this.athlete.ub_current"/>
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Bars Working on" v-model="ub_working" :list="this.athlete.ub_working"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Beam Competed Skills" v-model="bb_current" :list="this.athlete.bb_current"/>
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Beam Working on" v-model="bb_working" :list="this.athlete.bb_working"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Floor Competed Skills" v-model="fx_current" :list="this.athlete.fx_current" />
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <list-skills label="Floor Working on" v-model="fx_working" :list="this.athlete.fx_working"/>
      </v-col>
    </v-row>    

   <v-snackbar
      v-model="snackbar"
    >
      Your registration request was submitted successfully!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
      </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import LabelDataField from "../../components/LabelDataField.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
import ListSkills from "../../components/ListSkills.vue";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: { LabelDataField,ListSkills,SimpleBackButton },
  data() {
    return {
      athlete: [],
      snackbar: false
    }
  },
  async mounted() {
    const {id, success} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
    if (success) {
      this.snackbar = true;
    }
  },
  methods: {
    async getPictureForVcard(url) {
    const blob = await fetch(url).then(r => r.blob());
    const dataUrl = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        return reader.result;
      }
    });
    return dataUrl
    // now do something with `dataUrl`
  },
    downloadToFile(content, filename, contentType) {
      const a = document.createElement('a');
      const file = new Blob([content], { type: contentType });

      a.href = URL.createObjectURL(file);
      a.download = filename;
      a.click();

      URL.revokeObjectURL(a.href);
    },
    async makeVCard() {
      const makeVCardVersion = () => `VERSION:3.0`;
      const makeVCardInfo = () => `N:${this.athlete.athleteFirstName} ${this.athlete.athleteLastName} - ${this.athlete.athleteLevel} (${this.athlete.gymName})`;
      const makeVCardNote = () => `NOTE: Guardian: ${this.athlete.parentFirstName} ${this.athlete.parentLastName} | ${this.athlete.parentPhoneNumber} | ${this.athlete.parentEmail} \\nCoach: ${this.athlete.coachFirstName} ${this.athlete.coachLastName} | ${this.athlete.coachPhoneNumber} | ${this.athlete.coachEmail} `;
      const makeVCardName = () => `FN:${this.athlete.athleteFirstName} ${this.athlete.athleteLastName} `;
      const makeVCardOrg = () => `ORG:${this.athlete.gymName} `;
      const makeVCardTitle = () => `TITLE:${this.athlete.athleteLevel}`;
      const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${this.athlete.parentPhoneNumber}`;
      const makeVCardEmail = (email) => `EMAIL:${this.athlete.athleteEmail}`;
      const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;

const vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardInfo()}
${makeVCardNote()}
${makeVCardName()}
${makeVCardOrg()}
${makeVCardTitle()}
PHOTO;TYPE=JPEG;VALUE=URI:${this.profileImage}
${makeVCardTel()}
${makeVCardEmail()}
${makeVCardTimeStamp()}
BDAY:${this.athlete.athleteDob}
END:VCARD`;
      console.log(vcard)
      this.downloadToFile(vcard, 'vcard.vcf', 'text/vcard');
      }
  },
  computed: {
    isProfileComplete: function() {
      const events = ['vt_current','vt_working','ub_current','ub_working','bb_current','bb_working','fx_current','fx_working']
      let complete = true;
      if (this.athlete) {
        for (let i = 0; i < events.length; i++) {
          complete = complete && this.athlete[events[i]]?.length > 0;
        }
      }
      return complete && !!this.athlete.athleteInstagram && !!this.athlete.athleteYoutube && !!this.athlete.athleteGymStyle;
    },
    profileImage: function() {
      // return `https://drive.google.com/uc?export=view&id=${this.athlete.profile_picture_id}`
      // return `http://drive.usercontent.google.com/download?id=${this.athlete.profile_picture_id}&export=view&authuser=0`
      return `https://lh3.googleusercontent.com/d/${this.athlete.profile_picture_id}=w1000?authuser=1/view`
      // return `/${this.athlete.gymName}-${this.athlete.athleteFirstName}_${this.athlete.athleteLastName}.png`
    }
  }
}
</script>

<style lang="scss">
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }

  .official-profile-picture {
    width:100%;
    border-radius: 4px;
    border: 2px solid #DDD;
    overflow: hidden;
  }
  .grad-year {
    padding: 0.5rem 1rem;
    border-radius: .25rem;
    margin-right: 1rem;
    color: white;
    font-size: 1.25rem;
    &.y2025 {
      background-color: #ffb700;
    }
    &.y2026 {
      background-color: #5c3b69;
    }
    &.y2027 {
      background-color: #e64c9b;
    }
    &.y2028 {
      background-color: #46afbf;
    }
    &.y2029 {
      background-color: #5980a0;
    }
    &.y2030 {
      background-color: #ff0090;
    }
    &.y2031 {
      background-color: #ff8800;
    }
    &.y2032 {
      background-color: #575757;
    }
  }
  .athlete-full-name {
    font-size: 2rem;
  }
  .committed-pill {
    font-size: 0.6rem;
    padding: 0.1rem 0.3rem;
    border-radius: 1rem;
    margin-top: 0.125rem;
    display: inline-block;
    &.committed {
      color: #ce2702;
      border: 1px solid #ce2702;
    }
    &.uncommitted {
      color: #288e00;
      border: 1px solid #288e00;
    }
  }
  .contact-card {
    margin-left: 0.5rem;
    font-size: 0.6rem !important;
  }
</style>