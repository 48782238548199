<template>
  <v-container class="px-10">
      <simple-back-button/>
      <h1 class="full-width">Gymnastics Profile</h1>
        <alert 
          :success="isProfileComplete"
          :textSuccess="`You have signed this waiver for <b>${this.athlete.athleteFirstName}</b> on ${this.athlete.waiver_signed_on}`"
          :textError="`<b>You have not completed your gymnastics profile</b> Do not leave any field blank or empty. Use n/a for fields that do not apply to you`"
        />
    <v-row class="mt-4">
      <v-col>
        <v-text-field class="offset-label" v-model="gymStyle" label="GymStyle URL" outlined prepend-inner-icon="mdi-web">
                  <v-btn small outlined color="green" slot="append" @click="saveChanges({'athleteGymStyle': gymStyle})" v-if="gymStyle != this.athlete.athleteGymStyle">Save Changes</v-btn>
        </v-text-field>
        <v-text-field class="offset-label" v-model="instagram" label="Instagram URL" outlined prepend-inner-icon="mdi-instagram">
                  <v-btn small outlined color="green" slot="append" @click="saveChanges({'athleteInstagram': instagram})" v-if="instagram != this.athlete.athleteInstagram">Save Changes</v-btn>
        </v-text-field>
        <v-text-field class="offset-label" v-model="youtube" label="Youtube URL" outlined prepend-inner-icon="mdi-youtube">
                  <v-btn small outlined color="green" slot="append" @click="saveChanges({'athleteYoutube': youtube})" v-if="youtube != this.athlete.athleteYoutube">Save Changes</v-btn>
        </v-text-field>
        
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Vault Competed Skills" v-model="vt_current" :list="this.athlete.vt_current" :onAdd="addSkill('vt_current',this.vt_current)" :onDelete="deleteSkill('vt_current')" />
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Vault Working on" v-model="vt_working" :list="this.athlete.vt_working" :onAdd="addSkill('vt_working',this.vt_working)" :onDelete="deleteSkill('vt_working')"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Bars Competed Skills" v-model="ub_current" :list="this.athlete.ub_current" :onAdd="addSkill('ub_current', this.ub_current)" :onDelete="deleteSkill('ub_current')" />
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Bars Working on" v-model="ub_working" :list="this.athlete.ub_working" :onAdd="addSkill('ub_working',this.ub_working)" :onDelete="deleteSkill('ub_working')"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Beam Competed Skills" v-model="bb_current" :list="this.athlete.bb_current" :onAdd="addSkill('bb_current',this.bb_current)" :onDelete="deleteSkill('bb_current')" />
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Beam Working on" v-model="bb_working" :list="this.athlete.bb_working" :onAdd="addSkill('bb_working',this.bb_working)" :onDelete="deleteSkill('bb_working')"/>
      </v-col>
    </v-row>

    <v-divider class="my-4"/>
    <v-row class="mt-4">
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Floor Competed Skills" v-model="fx_current" :list="this.athlete.fx_current" :onAdd="addSkill('fx_current',this.fx_current)" :onDelete="deleteSkill('fx_current')" />
      </v-col>
      <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
        <add-skill label="Floor Working on" v-model="fx_working" :list="this.athlete.fx_working" :onAdd="addSkill('fx_working',this.fx_working)" :onDelete="deleteSkill('fx_working')"/>
      </v-col>
    </v-row>    
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import AddSkill from "../../components/AddSkill.vue";
import Alert from "../../components/Alert.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {Alert, AddSkill, SimpleBackButton},
  data() {
    return {
      athlete: {},
      vt_current: null,
      vt_working: null,
      ub_current: null,
      ub_working: null,
      bb_current: null,
      bb_working: null,
      fx_current: null,
      fx_working: null,
      gymStyle: '',
      youtube: '',
      instagram: '',

    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
    this.gymStyle = this.athlete.athleteGymStyle;
    this.youtube = this.athlete.athleteYoutube;
    this.instagram = this.athlete.athleteInstagram;
  },
  methods: {
    addSkill(key, val) { 
      return async () => {
        const index =  this.athlete[key].indexOf(val);
        if(index < 0) {
          const keyValue = {};
          this.athlete[key].push(val);
          keyValue[key] = this.athlete[key];
          console.log(keyValue)
          this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
        }
        this[key] = "";
      }
    },
    deleteSkill(key) {
      return async (item) => {
        const index =  this.athlete[key].indexOf(item);
        if (index > -1) { // only splice array when item is found
          this.athlete[key].splice(index, 1); // 2nd parameter means remove one item only
          const keyValue = {};
          keyValue[key] = this.athlete[key];
          this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
        }
      }
    },
    async saveChanges(keyValue) {
                this.athlete = (await api.post("/updateProfile", {id: this.athlete._id, keyValue})).data?.data?.[0] || {};
    }
  },
  computed: {
      isChanged: function(original, newValue) {
        return () => newValue !== original;
      },
      waiverImage: function() {
          return `https://lh3.googleusercontent.com/d/${this.athlete.waiver_signed_id}=w1000?authuser=1/view`
      },
      isProfileComplete: function() {
        const events = ['vt_current','vt_working','ub_current','ub_working','bb_current','bb_working','fx_current','fx_working']
        let complete = true;
        if (this.athlete) {
          for (let i = 0; i < events.length; i++) {
            complete = complete && this.athlete[events[i]]?.length > 0;
          }
        }
        return complete && !!this.athlete.athleteInstagram && !!this.athlete.athleteYoutube && !!this.athlete.athleteGymStyle;
      }
  },
}
</script>

<style lang="scss">
  .left-lined {
    border-left: 2px dotted #EEE;
    padding-left: 1.5rem;
  }

  .waiver-picture {
    width:90%;
    border-radius: 16px;
    border: 4px solid #DDD;
    overflow: hidden;
  }
  .offset-label .v-input .v-label--active{
    transform: translateX(-20%) translateX(-7.5px) translateY(-24px) scale(0.75) !important;
  }
</style>