<template>
  <v-container>
    <h1 class="pt-2 my-3">High Performance Camp Registration</h1>

    <v-alert
      color="red"
      outlined
      text
      type="error"
    >Upon completing this registration you will receive a confirmation email from us requesting you to submit a $75 <b>non refundable</b> down payment to secure your spot. All instructions on how to proceed with that payment will be in the email</v-alert>
    <v-alert
      color="info"
      outlined
      text
      type="info"
    ><b> Scratch date: </b>For payment purposes, all scratches must be informed via email by no later than September 6th 2024. Scratches communicated after <b>September 6th 2024</b> are not eligible for refunds or payment waiver.</v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row><v-col>
        <h2 class="pt-1"> Gymnast Details </h2>
      </v-col></v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="athleteFirstName" label="Gymnast's First Name" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="athleteLastName" label="Gymnast's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field
            v-model="athleteDob" label="DOB" v-mask="'##/##/####'" placeholder="MM/DD/YYYY" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="athleteEmail" label="Gymnast's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
          <v-autocomplete 
            outlined
            label="Gym Name: Type your gym name OR pick from the list"
            v-model="gymName" 
            full-width  
            :items="gymsList" 
            item-text="name" 
            item-value="name" 
            no-data-text=""
            :search-input.sync="search"
            :rules="[...rules.required]"
            hide-details
          >
          </v-autocomplete>
          <small>The gym list that pops up is just a suggestion list based on gyms that have already filled out the registration. As you type your gym name, if it not yet on the list it will be added to the bottom of the list, continue to type without any problems, then click on the gym name with the gray background.</small>
        </v-col>

        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="levels" v-model="athleteLevel" label="Level" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="gradYears" v-model="athleteGradYear" label="Grad Year" outlined required :rules="[...rules.required]" />
        </v-col>

      </v-row>

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="leoSizes" v-model="athleteLeoSize" label="Leo Size" outlined required hide-details :rules=" athleteRegisteredFor == 'Apex High Performance Camp 2024 ($425)' ? [...rules.required] : []" />
          <small class="size-chart" @click="openSizeChart()">Size Chart</small>
        </v-col>
      </v-row>

      <v-row><v-col>
        <h2 class="pt-1"> Parent | Guardian Details </h2>
      </v-col></v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field v-model="parentFirstName" label="Parent's First Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="parentLastName" label="Parent's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">    
          <v-text-field
            v-model="parentPhoneNumber"
            :rules="[... rules.required, ...rules.coachPhoneNumber]"
            label="Parent's phone number"
            v-mask="'(###) ###-####'"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="parentEmail" label="Parent's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row> 

      <v-row><v-col>
        <h2 class="pt-1"> Coach Details </h2>
      </v-col></v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field v-model="coachFirstName" label="Coach's First Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="coachLastName" label="Coach's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">    
          <v-text-field
            v-model="coachPhoneNumber"
            :rules="[... rules.required, ...rules.coachPhoneNumber]"
            label="Coach's phone number"
            v-mask="'(###) ###-####'"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="coachEmail" label="Coach's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row>
    </v-form>

      <v-footer class="p-5"> 
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-footer>
      <v-dialog
        v-model="dialog"
        max-width="900"
      >
        <img src="/sizing_chart.png" class="size-chart-img"/>
      </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/server-api";
export default {
  data:()=>({
    dialog: false,
    gymsList: ['test 1', 'test 2'],
    search: null,
    athleteFirstName:'',
    athleteLastName:'',
    athleteDob:'',
    athleteEmail:'',
    gymName:'',
    athleteLevel:'',
    athleteGradYear:'',
    athleteGPA:'',
    athleteRegisteredFor:'Apex High Performance Camp 2024 ($425)',
    athleteLeoSize:'',
    athleteTeeSize:'',
    athleteTankSize:'',
    athleteShortSize:'',
    parentFirstName:'',
    parentLastName:'',
    parentEmail:'',
    parentPhoneNumber:'',
    coachFirstName:'',
    coachLastName:'',
    coachPhoneNumber:'',
    coachEmail:'',
    rules: {
      name:[],
      required: [v => !!v || 'Required'],
      email: [
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      usagNumber: [],
    },
    leoSizes: [
      'CSM',
      'INT',
      'CME',
      'CLA',
      'AXS',
      'ASM',
      'AME',
      'ALA',
      'AXL',
      'A2XL'
    ],
    teeSizes: [
      'XS',
      'SM',
      'MD',
      'LG',
      'XL',
      'XXL',
      'XXXL'
    ],
    regFor: [
      'Gymnastics HP Showcase ($475)',
      'Acro & Tumbling ($375)',
      'Both - Acro & Tumbling plus All Levels - ($400)',
      'Both - Gym HP & A&T - ($500)',
      'All Levels - ($275)',
    ],
    valid: false,
    rosterValid: false,
  }),
  methods: {
    openSizeChart() {
      this.dialog = true;
    },

    async validate() {
     const v = this.$refs.form.validate();

     if (v) {
      const payload = {
        athleteFirstName: this.athleteFirstName,
        athleteLastName: this.athleteLastName,
        campType: 'Apex High Performance Camp 2024',
        athleteDob: this.athleteDob,
        athleteEmail: this.athleteEmail,
        gymName: this.gymName,
        athleteLevel: this.athleteLevel,
        athleteGradYear: this.athleteGradYear,
        athleteGPA: this.athleteGPA,
        athleteRegisteredFor: this.athleteRegisteredFor,
        athleteLeoSize: this.athleteLeoSize,
        athleteTeeSize: this.athleteTeeSize,
        athleteTankSize: this.athleteTankSize,
        athleteShortSize: this.athleteShortSize,
        parentFirstName: this.parentFirstName,
        parentLastName: this.parentLastName,
        parentEmail: this.parentEmail,
        parentPhoneNumber: this.parentPhoneNumber,
        coachFirstName: this.coachFirstName,
        coachLastName: this.coachLastName,
        coachPhoneNumber: this.coachPhoneNumber,
        coachEmail: this.coachEmail,
      };

      const res = await api.post("/hpc_register", payload);

      // register
      // get the registration id
      // provide as confirmation number or to perform future tasks
      // pass it to sign now as well
      if(res.data.status == "success" || res.status == "success") {
        const v = this.$refs.form.reset();
        this.$router.push(`/highperformancecamp/regSuccessful`)
      } else {
      console.log("3:",res.data, res.data.status, res.status)
        // stay on this page and display a toaster error
      }
     }
    },

    reset: () => true,
    submit: () => true,
  },
  async mounted() {
    this.gymsList = (await api.post("/getGymsList")).data.data;
  },
  watch: {
    search(val){
      const found = this.gymsList.find(element => element.toUpperCase() === val.toUpperCase());
      if (!found) {
        this.gymsList.pop();
        this.gymsList.push(val.toUpperCase());
      }

    }
  },
  computed: {
    gradYears: () => {
      const output = [];
      for(let i = 2024; i <= 2031; i += 1) {
        output.push(i)
      }
      return output;
    },
    levels: () => {
      let output = [];
      for(let i = 2; i <= 10; i += 1) {
        output.push(`Level ${i}`)
      }
      output = output.concat(['Xcel Silver','Xcel Gold','Xcel Platinum','Xcel Diamond','Xcel Sapphire'])

      return output;
    }
  }
}
</script>
<style lang="scss">
.uppercase input{
  text-transform: uppercase !important;
}
.size-chart {
  color: #369;
  cursor: pointer;
  &:HOVER {
    color: rgb(223, 115, 0);
  }
}
.size-chart-img {
  display: block;
  border: 3px solid white ;
}
</style>