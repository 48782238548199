<template>
<v-container>
  <v-alert type="error" outlined>
    We have changed a couple of things around.<b>Use the links below to continue to your destination</b>
  </v-alert>
  <v-spacer/>
  <div class="full-width">
    <v-btn color="info" :href="`/collegeshowcase/waiver?id=${userid}`" outlined x-large class="full-width"> Click   to Sign a waiver</v-btn>
    <v-btn color="info" :href="`/collegeshowcase/profilePicture?id=${userid}`" outlined x-large class="full-width"> Click to Upload a profile Picture</v-btn>
    <v-btn color="info" :href="`/collegeshowcase/regDetails?id=${userid}`" outlined x-large class="full-width"> Click to Edit Your Profile</v-btn>
    <v-btn color="info" :href="`/collegeshowcase/gymnasticsProfile?id=${userid}`" outlined x-large class="full-width"> Click to update your skills</v-btn>
  </div>
</v-container>  
</template>
<script>
  export default {
    components: {},
    data:()=>({
      userid: null
    }),
    async mounted() {
     const {id} = this.$route.query;
     this.userid = id;
    }
  }
</script>
<style lang="scss">
  .full-width {
    a {
      margin-top: 1rem;
    }
  }
</style>