<template>
  <v-container>
    <simple-back-button/>

    <h1 class="pt-2 my-3">Edit Registration</h1>
    <v-alert
      color="red"
      outlined
      text
      type="error"
    ><b> Scratch date: </b>For payment purposes, all scratches must be informed via email by no later than May 31st 2024. Scratches communicated after <b>May 31st 2024</b> are not eligible for refunds or payment waiver.</v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >

      <v-row>
        <v-col  >
          <h2 class="pt-1"> Gymnast Details </h2>
        </v-col>
        <v-col align="right" cols="12" xs="12" sm="4">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Save changes to registration
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" class="pt-0 pb-0">
          <v-checkbox v-model="athleteCommitted" label="I am committed to a college"></v-checkbox>
        </v-col>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0 mb-8" v-if="athleteCommitted">
          <v-text-field v-model="athleteCommittedTo" label="College Committed To" outlined />
        </v-col>
        
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="athleteFirstName" label="Gymnast's First Name" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="athleteLastName" label="Gymnast's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field
            v-model="athleteDob" label="DOB" v-mask="'##/##/####'" placeholder="MM/DD/YYYY" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="athleteEmail" label="Gymnast's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" sm="6" class="pt-0 pb-0">
          <v-autocomplete 
            outlined
            label="Gym Name: Type your gym name OR pick from the list"
            v-model="gymName" 
            full-width  
            :items="gymsList" 
            item-text="name" 
            item-value="name" 
            no-data-text=""
            :search-input.sync="search"
            :rules="[...rules.required]"
            hide-details
          >
          </v-autocomplete>
          <small>The gym list that pops up is just a suggestion list based on gyms that have already filled out the registration. As you type your gym name, if it not yet on the list it will be added to the bottom of the list, continue to type without any problems, then click on the gym name with the gray background.</small>
        </v-col>

        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="levels" v-model="athleteLevel" label="Level" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="gradYears" v-model="athleteGradYear" label="Grad Year" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field v-model="athleteGPA" label="GPA" outlined required :rules="[...rules.required]" />
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-select :items="regFor" v-model="athleteRegisteredFor" label="Register For" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0" v-if="athleteRegisteredFor == 'Gymnastics HP Showcase ($475)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)'">
          <v-select :items="leoSizes" v-model="athleteLeoSize" label="Leo Size" outlined required hide-details :rules=" athleteRegisteredFor == 'Gymnastics HP Showcase ($475)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' ? [...rules.required] : []" />
          <small class="size-chart" @click="openSizeChart()">Size Chart</small>
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0" v-if=" athleteRegisteredFor == 'All Levels - ($275)' || athleteRegisteredFor == 'Gymnastics HP Showcase ($475)' || athleteRegisteredFor == 'Acro & Tumbling ($375)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' || athleteRegisteredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'">
          <v-select :items="teeSizes" v-model="athleteTeeSize" label="T-shirt Size" outlined required :rules="[...rules.required]" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0" v-if="athleteRegisteredFor == 'Acro & Tumbling ($375)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' || athleteRegisteredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'">
          <v-select :items="teeSizes" v-model="athleteTankSize" label="Fitted TankTop Size" outlined required :rules="athleteRegisteredFor == 'Acro & Tumbling ($375)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' || athleteRegisteredFor == 'Both - Acro & Tumbling plus All Levels - ($400)' ? [...rules.required] : []" />
        </v-col>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0" v-if="athleteRegisteredFor == 'Acro & Tumbling ($375)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' || athleteRegisteredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'">
          <v-select :items="teeSizes" v-model="athleteShortSize" label="Fitted Shorts Size" outlined required :rules="athleteRegisteredFor == 'Acro & Tumbling ($375)' || athleteRegisteredFor == 'Both - Gym HP & A&T - ($500)' || athleteRegisteredFor == 'Both - Acro & Tumbling plus All Levels - ($400)' ? [...rules.required] : []" />
        </v-col>
      </v-row>

      <v-row><v-col>
        <h2 class="pt-1"> Parent | Guardian Details </h2>
      </v-col></v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field v-model="parentFirstName" label="Parent's First Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="parentLastName" label="Parent's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">    
          <v-text-field
            v-model="parentPhoneNumber"
            :rules="[... rules.required, ...rules.coachPhoneNumber]"
            label="Parent's phone number"
            v-mask="'(###) ###-####'"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="parentEmail" label="Parent's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row> 

      <v-row><v-col>
        <h2 class="pt-1"> Coach Details </h2>
      </v-col></v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="2" class="pt-0 pb-0">
          <v-text-field v-model="coachFirstName" label="Coach's First Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field v-model="coachLastName" label="Coach's Last Name" outlined required :rules="[...rules.required]" />
        </v-col>

        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">    
          <v-text-field
            v-model="coachPhoneNumber"
            :rules="[... rules.required, ...rules.coachPhoneNumber]"
            label="Coach's phone number"
            v-mask="'(###) ###-####'"
            required
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" xs="12" sm="4" class="pt-0 pb-0">
          <v-text-field v-model="coachEmail" label="Coach's Email" outlined required :rules="[...rules.required, ...rules.email]" />
        </v-col>
      </v-row>
    </v-form>

      <v-footer class="p-5"> 
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Save changes to registration
        </v-btn>
      </v-footer>
      <v-dialog
        v-model="dialog"
        max-width="900"
      >
        <img src="/sizing_chart.png" class="size-chart-img"/>
      </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/server-api";
import SimpleBackButton from "../../components/SimpleBackButton.vue"
export default {
  components: {SimpleBackButton},
  data:()=>({
    dialog: false,
    gymsList: ['test 1', 'test 2'],
    search: null,
    athleteFirstName:'',
    athleteLastName:'',
    athleteDob:'',
    athleteEmail:'',
    gymName:'',
    athleteLevel:'',
    athleteGradYear:'',
    athleteGPA:'',
    athleteRegisteredFor:'',
    athleteCommitted:false,
    athleteCommittedTo:'',
    athleteLeoSize:'',
    athleteTeeSize:'',
    athleteTankSize:'',
    athleteShortSize:'',
    parentFirstName:'',
    parentLastName:'',
    parentEmail:'',
    parentPhoneNumber:'',
    coachFirstName:'',
    coachLastName:'',
    coachPhoneNumber:'',
    coachEmail:'',
    rules: {
      name:[],
      required: [v => !!v || 'Required'],
      email: [
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      usagNumber: [],
    },
    leoSizes: [
      'CSM',
      'INT',
      'CME',
      'CLA',
      'AXS',
      'ASM',
      'AME',
      'ALA',
      'AXL',
      'A2XL'
    ],
    teeSizes: [
      'XS',
      'SM',
      'MD',
      'LG',
      'XL',
      'XXL',
      'XXXL'
    ],
    regFor: [
      'Gymnastics HP Showcase ($475)',
      'Acro & Tumbling ($375)',
      'Both - Gym HP & A&T - ($500)',
      'All Levels - ($275)',
    ],
    valid: false,
    rosterValid: false,
  }),
  methods: {
    openSizeChart() {
      this.dialog = true;
    },

    async validate() {
     const v = this.$refs.form.validate();

     if (v) {
      const payload = {
        athleteFirstName: this.athleteFirstName,
        athleteLastName: this.athleteLastName,
        athleteDob: this.athleteDob,
        athleteEmail: this.athleteEmail,
        gymName: this.gymName,
        athleteLevel: this.athleteLevel,
        athleteGradYear: this.athleteGradYear,
        athleteGPA: this.athleteGPA,
        athleteRegisteredFor: this.athleteRegisteredFor,
        athleteCommitted: this.athleteCommitted,
        athleteCommittedTo: this.athleteCommittedTo,
        athleteLeoSize: this.athleteLeoSize,
        athleteTeeSize: this.athleteTeeSize,
        athleteTankSize: this.athleteTankSize,
        athleteShortSize: this.athleteShortSize,
        parentFirstName: this.parentFirstName,
        parentLastName: this.parentLastName,
        parentEmail: this.parentEmail,
        parentPhoneNumber: this.parentPhoneNumber,
        coachFirstName: this.coachFirstName,
        coachLastName: this.coachLastName,
        coachPhoneNumber: this.coachPhoneNumber,
        coachEmail: this.coachEmail,
      };

      const res = await api.post("/updateProfile", {id: this.$route.query.id, keyValue: payload});

      // register
      // get the registration id
      // provide as confirmation number or to perform future tasks
      // pass it to sign now as well

      if(res.data.status == "success") {
        const v = this.$refs.form.reset();
        this.$router.push(`/regDetails?id=${res.data.data[0]._id}&success=true`)
      } else {
        // stay on this page and display a toaster error
      }
     }
    },

    reset: () => true,
    submit: () => true,
  },
  async mounted() {
     const {id} = this.$route.query;
   this.gymsList = (await api.post("/getGymsList")).data.data;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};

       this.athleteFirstName= this.athlete.athleteFirstName;
        this.athleteLastName= this.athlete.athleteLastName;
        this.athleteDob= this.athlete.athleteDob;
        this.athleteEmail= this.athlete.athleteEmail;
        this.gymName= this.athlete.gymName;
        this.athleteLevel= this.athlete.athleteLevel;
        this.athleteGradYear= this.athlete.athleteGradYear;
        this.athleteGPA= this.athlete.athleteGPA;
        this.athleteRegisteredFor= this.athlete.athleteRegisteredFor;
        this.athleteCommitted= this.athlete.athleteCommitted;
        this.athleteCommittedTo= this.athlete.athleteCommittedTo;
        this.athleteLeoSize= this.athlete.athleteLeoSize;
        this.athleteTeeSize= this.athlete.athleteTeeSize;
        this.athleteTankSize= this.athlete.athleteTankSize;
        this.athleteShortSize= this.athlete.athleteShortSize;
        this.parentFirstName= this.athlete.parentFirstName;
        this.parentLastName= this.athlete.parentLastName;
        this.parentEmail= this.athlete.parentEmail;
        this.parentPhoneNumber= this.athlete.parentPhoneNumber;
        this.coachFirstName= this.athlete.coachFirstName;
        this.coachLastName= this.athlete.coachLastName;
        this.coachPhoneNumber= this.athlete.coachPhoneNumber;
        this.coachEmail= this.athlete.coachEmail;
  },
  watch: {
    search(val){
      const found = this.gymsList.find(element => element.toUpperCase() === val.toUpperCase());
      if (!found) {
        this.gymsList.pop();
        this.gymsList.push(val.toUpperCase());
      }

    }
  },
  computed: {
    gradYears: () => {
      const output = [];
      for(let i = 2024; i <= 2031; i += 1) {
        output.push(i)
      }
      return output;
    },
    levels: () => {
      let output = [];
      for(let i = 4; i <= 10; i += 1) {
        output.push(`Level ${i}`)
      }
      output = output.concat(['Xcel Platinum','Xcel Diamond','Xcel Sapphire'])
      output = output.concat(['IGC Diamond','IGC Silver','IGC Gold','IGC Platinum','IGC Premiere'])
      output.push(`Acro & Tumbling`)

      return output;
    }
  }
}
</script>
<style lang="scss">
.uppercase input{
  text-transform: uppercase !important;
}
.size-chart {
  color: #369;
  cursor: pointer;
  &:HOVER {
    color: rgb(223, 115, 0);
  }
}
.size-chart-img {
  display: block;
  border: 3px solid white ;
}
</style>