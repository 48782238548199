<template>
  <v-container class="px-10">
      <simple-back-button/>
      <h1 class="full-width text-center">Non refundable deposit payment details</h1>
      <v-alert
        color="red"
        outlined
        text
        type="error"
      >Your full payment is due by <b>{{duePaymentDep}}</b>. Please make a payment ar your earliest convenience to secure your spot in the showcase.</v-alert>
      <div class="full-width text-center">
       <how-to-pay type="deposit" type-of-payment="non refundable deposit payment" check-amount="75.00" venmo-amount="80.00" camp="Apex High Performance Camp 2024 - name of athlete"/>
      </div>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import HowToPay from "../HowToPay.vue"
import SimpleBackButton from "../../components/SimpleBackButton.vue"
import moment from "moment";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {HowToPay,SimpleBackButton},
  data() {
    return {
      athlete: [],
    }
  },

  async mounted() {
     const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
  },
  methods: {
  },
  computed: {
    duePaymentDep: function() {
      return moment(this.athlete.approvedDeniedOn, 'YYYY-MM-DD').add('2', 'weeks').format("MM/DD/YYYY");
    },
    duePaymentFull: function() {
      return this.athlete.approvedDeniedOn;
    }
  },
}
</script>

<style lang="scss">


</style>