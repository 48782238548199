import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import ViewMyRoster from "@/views/CollegeShowcase/ViewMyRoster.vue";
import CollegeShowcaseGeneralInfo from "@/views/CollegeShowcase/CollegeShowcaseGeneralInfo.vue";
import ListAllParticipants from "@/views/CollegeShowcase/ListAllParticipants.vue";
import UniformReport from "@/views/CollegeShowcase/UniformReport.vue";
import Roster from "@/views/CollegeShowcase/ViewEntireRoster.vue";
import WaiverPreview from "@/views/CollegeShowcase/WaiverPreview.vue";
import Waiver from "@/views/CollegeShowcase/Waiver.vue";
import SuggestedSkills from "@/views/CollegeShowcase/SuggestedSkills.vue";
import Home from "@/views/CollegeShowcase/Home.vue";
import ProfilePicture from "@/views/CollegeShowcase/ProfilePicture.vue";
import EditRegistration from "@/views/CollegeShowcase/EditRegistration.vue";
import GymnasticsProfile from "@/views/CollegeShowcase/GymnasticsProfile.vue";
import FullPayment from "@/views/CollegeShowcase/FullPayment.vue";
import DownPayment from "@/views/CollegeShowcase/DownPayment.vue";
import ApproveDenySingle from "@/views/CollegeShowcase/ApproveDenySingle.vue";
import ApproveDeny from "@/views/CollegeShowcase/ApproveDeny.vue";
import RegDetails from "@/views/CollegeShowcase/RegDetails.vue";
import Registration from "@/views/CollegeShowcase/Registration.vue";
import CollegeShowcaseParticipants2024 from "@/views/CollegeShowcase/Participants2024.vue";
import CollegeShowcaseOfficialProfile from "@/views/CollegeShowcase/OfficialProfile.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import ShowcaseRegSuccessful from "@/views/CollegeShowcase/RegistrationSuccessful.vue";

import HPCInfo from "@/views/HighPerformanceCamp/Info.vue";
import HPCReg from "@/views/HighPerformanceCamp/Registration.vue";
import HPCRegSuccessful from "@/views/HighPerformanceCamp/RegistrationSuccessful.vue";
import HPCApprovedenySingle from "@/views/HighPerformanceCamp/ApproveDenySingle.vue";
import HPCDownPayment from "@/views/HighPerformanceCamp/DownPayment.vue";
import HPCRegDetails from "@/views/HighPerformanceCamp/RegDetails.vue";
import HPCWaiverPreview from "@/views/HighPerformanceCamp/WaiverPreview.vue";
import HPCWaiver from "@/views/HighPerformanceCamp/Waiver.vue";
import HPCFullPayment from "@/views/HighPerformanceCamp/FullPayment.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'College Showcase'
    },
  },
  {
    path: '/registration',
    alias: '/collegeshowcase/registration',
    name: 'collegeshowcase_Registration',
    component: Registration,
    meta: {
      title: 'Registration'
    },
  },
  {
    path: '/collegeshowcase/info',
    name: 'collegeShowcase_info',
    component: CollegeShowcaseGeneralInfo,
    meta: {
      title: 'CollegeShowcase'
    },
  },
  {
    path: '/suggestedskills',
    alias: '/collegeshowcase/suggestedskills',
    name: 'collegeshowcass_suggestedSkills',
    component: SuggestedSkills,
    meta: {
      title: 'Suggested Skills'
    },
  },
  {
    path: '/approvedeny',
    alias: '/collegeshowcase/approvedeny',
    name: 'collegeshowcase_approveDeny',
    component: ApproveDeny,
    meta: {
      title: 'Approve or Deny'
    },
  },
  {
    path: '/approvedenysingle',
    alias: '/collegeshowcase/approvedenysingle',
    name: 'collegeshowcase_approveDenySingle',
    component: ApproveDenySingle,
    meta: {
      title: 'Approve or Deny'
    },
  },
  {
    path: '/viewmyroster',
    alias: '/collegeshowcase/viewmyroster',
    name: 'collegeshowcase_viewMyRoster',
    component: ViewMyRoster,
    meta: {
      title: 'View My Roster'
    },
  },
  {
    path: '/roster',
    alias: '/collegeshowcase/roster',
    name: 'collegeshowcase_roster',
    component: Roster,
    meta: {
      title: 'Roster'
    },
  }, 

  {
    path: '/waiver',
    alias: '/collegeshowcase/waiver',
    name: 'collegeshowcase_waiver',
    component: Waiver,
    meta: {
      title: 'Waiver'
    },
  }, 
  {
    path: '/waiverPreview',
    alias: '/collegeshowcase/waiverPreview',
    name: 'collegeshowcase_waiverPreview',
    component: WaiverPreview,
    meta: {
      title: 'Waiver Preview'
    },
  }, 
  {
    path: '/gymnasticsProfile',
    alias: '/collegeshowcase/gymnasticsProfile',
    name: 'collegeshowcase_gymnasticsProfile',
    component: GymnasticsProfile,
    meta: {
      title: 'Gymnastics Profile'
    },
  }, 
  {
    path: '/downPayment',
    alias: '/collegeshowcase/downPayment',
    name: 'collegeshowcase_downPayment',
    component: DownPayment,
    meta: {
      title: 'Down Payment'
    },
  }, 
  {
    path: '/fullPayment',
    alias: '/collegeshowcase/fullPayment',
    name: 'collegeshowcase_fullPaymnet',
    component: FullPayment,
    meta: {
      title: 'Full Payment'
    },
  },  
  {
    path: '/regDetails',
    alias: '/collegeshowcase/regDetails',
    name: 'collegeshowcase_waiver',
    component: RegDetails,
    meta: {
      title: 'Registration Details'
    },
  }, 
  {
    path: '/profilePicture',
    alias: '/collegeshowcase/profilePicture',
    name: 'collegeshowcase_picture',
    component: ProfilePicture,
    meta: {
      title: 'Profile Picture'
    },
  },
  {
    path: '/editRegistration',
    alias: '/collegeshowcase/editRegistration',
    name: 'collegeshowcase_editRegistration',
    component: EditRegistration,
    meta: {
      title: 'Edit Registration'
    },
  },
  {
    path: '/listParticipants',
    alias: '/collegeshowcase/listParticipants',
    name: 'collegeshowcase_listParticipants',
    component: ListAllParticipants,
    meta: {
      title: 'List All Participants'
    },
  },
  {
    path: '/report_uniform',
    alias: '/collegeshowcase/report_uniform',
    name: '/collegeshowcase_report_uniform',
    component: UniformReport,
    meta: {
      title: 'Uniforms'
    },
  },
  {
    path: '/collegeshowcase/participants2024',
    alias: '/collegeshowcase/participants2024',
    name: '/collegeshowcaseParticipants2024',
    component: CollegeShowcaseParticipants2024,
    meta: {
      title: 'College Showcase Participants 2024'
    },
  },
  {
    path: '/collegeshowcase/officialProfile',
    name: '/officialProfile',
    component: CollegeShowcaseOfficialProfile,
    meta: {
      title: 'College Showcase Profile'
    },
  },
    
  {
    path: '/regSuccessful',
    alias: '/collegeshowcase/regSuccessful',
    name: '/collegeshowcase_regSuccessful',
    component: ShowcaseRegSuccessful,
    meta: {
      title: 'collegeshowcase Registration Successfull'
    },
  },
  // HPC ROUTES
  {
    path: '/highperformancecamp/info',
    name: '/highperformancecamp_report_uniform',
    component: HPCInfo,
    meta: {
      title: 'HPC Info'
    },
  },
  {
    path: '/highperformancecamp/registration',
    name: '/highperformancecamp_registration',
    component: HPCReg,
    meta: {
      title: 'HPC Registration'
    },
  },
  {
    path: '/highperformancecamp/regSuccessful',
    name: '/highperformancecamp_regSuccessful',
    component: HPCRegSuccessful,
    meta: {
      title: 'HPC Registration Successfull'
    },
  },
  {
    path: '/highperformancecamp/regDetails',
    name: '/highperformancecamp_regDetails',
    component: HPCRegDetails,
    meta: {
      title: 'HPC Registration Details'
    },
  },
  {
    path: '/highperformancecamp/approvedenysingle',
    name: '/highperformancecamp_approvedenysingle',
    component: HPCApprovedenySingle,
    meta: {
      title: 'HPC Registration Approve Deny Single'
    },
  },
  {
    path: '/highperformancecamp/downPayment',
    name: '/highperformancecamp_downPayment',
    component: HPCDownPayment,
    meta: {
      title: 'HPC Registration Down Payment'
    },
  },
  {
    path: '/highperformancecamp/waiver',
    name: 'highperformancecamp_waiver',
    component: HPCWaiver,
    meta: {
      title: 'Waiver'
    },
  }, 
  {
    path: '/highperformancecamp/waiverPreview',
    name: 'highperformancecamp_waiverPreview',
    component: HPCWaiverPreview,
    meta: {
      title: 'Waiver Preview'
    },
  },
  {
    path: '/highperformancecamp/fullPayment',
    name: 'highperformancecampe_fullPaymnet',
    component: HPCFullPayment,
    meta: {
      title: 'Full Payment'
    },
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {selector: to.hash}
        //Or for Vue 3:
        //return {el: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const athleteName = to.params.athletename;
  if(athleteName){
    document.title = `${to.meta.title} | ${athleteName}`;
  }else{
    document.title = to.meta.title ? to.meta.title : "Apex Showcase 2020";
  }
  next();
});

export default router
