<template>
  <v-container>
    <div v-if="registration.length == 0" class="d-flex justify-center">
      <v-card class="px-5 py-5 mt-5 retrieve-card" elevation="0" max-width="400">
        <v-card-title class="d-block text-center retrieve-title">Enter the information below to retrieve your roster</v-card-title>
        <v-alert 
          v-if="response && response.data.status == 'fail'"
          color="red"
          outlined
          text
          type="error"
        >{{response.data.message}}</v-alert>
        <v-text-field v-model="coachUsagNumber" label="Coach USAG number" hide-details outlined class="my-2" elevation="0"/>
        <v-text-field v-model="coachLastFourDigits" label="Last 4 digits of Coach's Phone number" hide-details outlined elevation="0"/>
        <v-btn :disabled="!coachUsagNumber || !coachLastFourDigits || coachLastFourDigits.length != 4" color="success" class="mt-5" @click="retrieveRoster()"> Retrieve</v-btn>
      </v-card>
    </div>
    <div v-if="registration.length > 0">
      <div class="d-flex registration-details-header">
        <h1 class="success--text">View Your Roster</h1>
      </div>
        <v-alert
        color="info"
        outlined
        text
        type="info"
      >We are currently reviewing your application against our available spots. Once we have completed the review you will receive an email at <b>{{this.registration[0].coachEmail}}</b> with next steps and details on how to submit your payment. </v-alert>

      <div v-if="registration.length > 0">
        <div class="reg-row">
          <div class="label">
            Gym | Club Name 
          </div>
          <div class="data">
            {{this.registration[0].gymName}}
          </div>
        </div>

        <div class="reg-row">
          <div class="label">
            Coach Name:
          </div>
          <div class="data">
            {{this.registration[0].coachName}} (USAG# {{this.registration[0].coachUsagNumber}})
          </div>
        </div>

        <div class="reg-row">
          <div class="label">
            Coach Contact:
          </div>
          <div class="data">
            e-mail: {{this.registration[0].coachEmail}} | Ph. no. {{this.registration[0].coachPhoneNumber}}
          </div>
        </div>

        <h2 class="mt-5">Here are ALL the Athletes associated with this Coach ({{this.registration.length}})</h2>
        <div v-for="(item, index) in this.registration" :key="index">
          <div>
            <v-btn icon  small tile class="mr-2" @click="confirmDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
            <b>{{item.athleteFirstName}} {{item.athleteLastName}}</b> ({{item.registeredFor}})
          <span v-if="incomplete(item)" class="not-approved small"> - Incomplete - Missing athlete bio</span>
          </div>
          <div class="d-sm-flex mb-3 athlete-row">
            <div> USAG# <b>{{item.athleteUsagNumber}}</b></div>
            <div><b>{{item.athleteLevel}}</b></div>
            <div> DOB <b>{{item.athleteDob}}</b></div>
            <div> Class of <b>{{item.athleteGradYear}}</b></div>
            <div v-if="item.registeredFor == 'Gymnastics ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Leo Size <b>{{item.leoSize}}</b></div>
            <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Tank Size <b>{{item.tankSize}}</b></div>
            <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Short Size <b>{{item.shortSize}}</b></div>
            <div> T-shirt Size <b>{{item.teeSize}}</b></div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="showDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card v-if="this.athleteToBeDeleted">
        <v-card-title class="text-h5 text-center red--text text--darken-2 justify-center">
          <div>Are you sure you want to remove</div>
          <div class="black--text">{{this.athleteToBeDeleted.athleteFirstName}} {{this.athleteToBeDeleted.athleteLastName}}</div>
          <div>from the roster</div>
        </v-card-title>
        <v-card-text>This action cannot be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="deleteUser()"
            dark
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/server-api";
export default {
  data() {
    return {
      registration: [],
      coachUsagNumber: null,
      coachLastFourDigits: null,
      response: null,
      showDeleteDialog: false,
      athleteToBeDeleted: null,
    }
  },
  methods: {
    incomplete(d){
      return !d.athleteGPA || !d.athleteYoutube || !d.AthleteInstagram || d.vt_current.length == 0 || d.vt_working.length == 0 || d.bb_current.length == 0 || d.bb_working.length == 0 || d.ub_current.length == 0 || d.ub_working.length == 0 || d.fx_current.length == 0 || d.fx_working.length == 0;
    },
    async retrieveRoster() {
      this.response = await api.post("/retrieveRoster", {coachUsagNumber: this.coachUsagNumber, coachLastFourDigits: this.coachLastFourDigits});
      this.registration = this.response.data.data;
    },
    confirmDelete(athlete) {
      this.showDeleteDialog = true;
      this.athleteToBeDeleted = athlete;
    },
    async deleteUser() {
      await api.post("/deleteAthleteById", {id: this.athleteToBeDeleted._id});
      this.registration = (await api.post("/athlete", {keyValue: {coachUsagNumber: this.coachUsagNumber}})).data;
      this.showDeleteDialog = false;
    },
  }
}
</script>

<style lang="scss">
.registration-details-header {
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}
.label {
    font-weight: bold;
    padding-top: 10px;
}
.athlete-row {
  border-bottom: 1px solid #CCC;
  padding-bottom: 15px;
  justify-content: space-between;
}
.approved, .not-approved {
  padding-left: 5px;
}
.approved {
  color:rgb(75, 128, 0);
}
.not-approved {
  color:rgb(128, 13, 0);
}
.athlete-row b {
  padding-right: 7px;
}
.small {
  font-size: 0.9rem;
}
.approve-deny-btn {
  min-width: 100px;
  width: 100px;
}
.approve-all-deny-all-btn {
  min-width: 130px;
  width: 130px;
}
.approve-all-container button {
  margin-left: 7px;
}
.retrieve-card {
  display: flex;
  flex-direction: column;
}
.retrieve-title {
  word-break: normal;
}
</style>
