<template>
  <v-container>
    <div class="px-10 mb-2 approve-deny-container">
      <h3 class="mb-4">Approve | Deny Registration </h3>
      <v-row>
        <v-col cols="12" xs="12" sm="6" class="mb-0 pb-0">
          <v-btn elevation="0" :disabled="!!this.athlete.approved && !!this.athlete.approvedDeniedOn" color="green" @click="approveDeny(true)" class="full-width text-white" hide-details>Approve</v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="6">
          <v-btn :disabled="!this.athlete.approved && !!this.athlete.approvedDeniedOn" color="red"  @click="approveDeny(false)" class="full-width text-white">Deny</v-btn>
        </v-col>
      </v-row>
    </div>
    <registration-details v-if="showDetails"/>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import {nextTick} from 'vue';
import api from "@/api/server-api";
import RegistrationDetails from "./RegDetails.vue"
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  data:()=>({
    showDetails: true,
    athlete: {}
  }),
  components: {RegistrationDetails},
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
  },
  methods: {
    async approveDeny(val){
      const {id} = this.$route.query;
      await api.post("/approveDenyAthlete", {id, keyValue: {approved: val, approvedDeniedOn: (new Date())} });
        this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
      this.showDetails = false;
      await this.$nextTick();
      this.showDetails = true; 
  }
  }
}
</script>
<style lang="scss">
.approve-deny-container {
  border: 1px dotted red;
  border-radius: 8px;
  padding: 1.5rem;
}
.text-white {
  color: white !important;
}
</style>