<template>
  <v-container>
    <div>
      <div class="d-flex registration-details-header">
        <h1 class="success--text">Apex College Showcase Roster <span v-if="registration.length > 0"> [{{registration.length}}]</span></h1>
      </div>

      <div v-if="registration.length == 0" class="d-flex justify-center">
        <v-card class="px-5 py-5 mt-5 retrieve-card" elevation="0" max-width="400">
          <v-card-title class="d-block text-center retrieve-title">This area is password protected for administrators only. Please provide your credentials</v-card-title>
          <v-alert 
            v-if="response && response.status == 'fail'"
            color="red"
            outlined
            text
            type="error"
          >{{response.message}}</v-alert>
          <v-text-field v-model="username" label="Username" hide-details outlined class="my-2" elevation="0"/>
          <v-text-field v-model="password" label="Password" type="password" hide-details outlined elevation="0"/>
          <v-btn :disabled="!username || !password" color="success" class="mt-5" @click="retrieveRoster()"> Retrieve</v-btn>
        </v-card>
      </div>
      
      <div v-if="registration.length > 0">
        <div v-for="(value, propName) in this.gyms" :key="propName">
          <h2 class="mt-5 pt-5">{{propName}} [{{value.length}}]</h2>
          <div v-for="(item, index2) in value" :key="index2">
            <div>
              <v-btn icon  small tile class="mr-2" @click="confirmDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
              <b>{{item.athleteFirstName}} {{item.athleteLastName}}</b> ({{item.registeredFor}})
            <span v-if="incomplete(item)" class="not-approved small"> - Incomplete - Missing athlete bio</span>
            </div>
            <div class="d-sm-flex mb-3 athlete-row">
              <div> USAG# <b>{{item.athleteUsagNumber}}</b></div>
              <div><b>{{item.athleteLevel}}</b></div>
              <div> DOB <b>{{item.athleteDob}}</b></div>
              <div> Class of <b>{{item.athleteGradYear}}</b></div>
              <div v-if="item.registeredFor == 'Gymnastics ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Leo Size <b>{{item.leoSize}}</b></div>
              <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Tank Size <b>{{item.tankSize}}</b></div>
              <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Short Size <b>{{item.shortSize}}</b></div>
              <div> T-shirt Size <b>{{item.teeSize}}</b></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="showDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card v-if="this.athleteToBeDeleted">
        <v-card-title class="text-h5 text-center red--text text--darken-2 justify-center">
          <div>Are you sure you want to remove</div>
          <div class="black--text">{{this.athleteToBeDeleted.athleteFirstName}} {{this.athleteToBeDeleted.athleteLastName}}</div>
          <div>from the roster</div>
        </v-card-title>
        <v-card-text>This action cannot be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="deleteUser()"
            dark
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/server-api";
export default {
  data() {
    return {
      registration: [],
      username: null,
      password: null,
      response: null,
      showDeleteDialog: false,
      athleteToBeDeleted: null,
      gym: {},

    }
  },

  methods: {
      incomplete(d){
        return !d.athleteGPA || !d.athleteYoutube || !d.AthleteInstagram || d.vt_current.length == 0 || d.vt_working.length == 0 || d.bb_current.length == 0 || d.bb_working.length == 0 || d.ub_current.length == 0 || d.ub_working.length == 0 || d.fx_current.length == 0 || d.fx_working.length == 0;
      },
      getGymList(arr) {
        const gyms = {};
        for (let i = 0; i < arr.length; i += 1) {
          if (!gyms[arr[i].gymName]) {
            gyms[arr[i].gymName] = [arr[i]];
          } else {
            gyms[arr[i].gymName].push(arr[i]);
          }
        }
        return gyms;
      },
      async getAthletes() {
        this.response = (await api.post("/all_athletes_secure", {username: this.username, password: this.password})).data;
        if(this.response.status == 'success') {
          this.registration = this.response.data;
          this.gyms = this.getGymList(this.registration);
        }
      },
      confirmDelete(athlete) {
        this.showDeleteDialog = true;
        this.athleteToBeDeleted = athlete;
      },
      async deleteUser() {
        this.getAthletes();
        this.showDeleteDialog = false;
      },
      async retrieveRoster() {
        this.getAthletes();
      }
    }
  }
</script>

<style lang="scss">
.registration-details-header {
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}
.label {
    font-weight: bold;
    padding-top: 10px;
}
.athlete-row {
  border-bottom: 1px solid #CCC;
  padding-bottom: 15px;
  justify-content: space-between;
}
.approved, .not-approved {
  padding-left: 5px;
}
.approved {
  color:rgb(75, 128, 0);
}
.not-approved {
  color:rgb(128, 13, 0);
}
.athlete-row b {
  padding-right: 7px;
}
.small {
  font-size: 0.9rem;
}
.approve-deny-btn {
  min-width: 100px;
  width: 100px;
}
.approve-all-deny-all-btn {
  min-width: 130px;
  width: 130px;
}
.approve-all-container button {
  margin-left: 7px;
}
.retrieve-card {
  display: flex;
  flex-direction: column;
}
.retrieve-title {
  word-break: normal;
}
</style>
