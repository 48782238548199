<template>
  <v-container>
    <div class="d-flex registration-details-header">
      <h1>Registration Details</h1>
      <div class="approve-all-container d-md-flex" v-if="registration.length > 0">
        <v-checkbox hide-details label="Send e-mail to coach" v-model="sendEmailToCoach" class="sendEmailToCoach mt-0 mb-2" />
        <v-btn color="success" class="approve-all-deny-all-btn sendEmailToCoach mt-0 mb-2" elevation="0" @click="approveDenyAll(true)">Approve All </v-btn>
        <v-btn color="error" class="approve-all-deny-all-btn sendEmailToCoach mt-0 mb-2" elevation="0" @click="approveDenyAll(false)">Deny All </v-btn>
      </div>
    </div>
    <div v-if="registration.length > 0">
      <div class="reg-row">
        <div class="label">
          Gym | Club Name 
        </div>
        <div class="data">
          {{this.registration[0].gymName}}
        </div>
      </div>

      <div class="reg-row">
        <div class="label">
          Coach Name:
        </div>
        <div class="data">
          {{this.registration[0].coachName}} (USAG# {{this.registration[0].coachUsagNumber}})
        </div>
      </div>

      <div class="reg-row">
        <div class="label">
          Coach Contact:
        </div>
        <div class="data">
          e-mail: {{this.registration[0].coachEmail}} | Ph. no. {{this.registration[0].coachPhoneNumber}}
        </div>
      </div>

      <h2 class="mt-5">Athletes</h2>
      <div v-for="(item, index) in this.registration" :key="index">
        <div>
          <v-btn icon  small tile class="mr-2" @click="confirmDelete(item)"><v-icon>mdi-delete</v-icon></v-btn>
          <b>{{item.athleteFirstName}} {{item.athleteLastName}}</b> | {{item.registeredFor}} -  
        <span v-if="item.approved" class="approved">(Approved)</span>
        <span v-if="!item.approved" class="not-approved">(Not Approved)</span>
        <span v-if="incomplete(item)" class="not-approved small"> - Incomplete</span>
        </div>
        <div class="d-sm-flex mb-3 athlete-row">
          <div> USAG# <b>{{item.athleteUsagNumber}}</b></div>
          <div><b>{{item.athleteLevel}}</b></div>
          <div> DOB <b>{{item.athleteDob}}</b></div>
          <div> Class of <b>{{item.athleteGradYear}}</b></div>
          <div v-if="item.registeredFor == 'Gymnastics ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Leo Size <b>{{item.leoSize}}</b></div>
          <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Tank Size <b>{{item.tankSize}}</b></div>
          <div v-if="item.registeredFor == 'Acro & Tumbling ($225)' || item.registeredFor == 'Both - WAG & A&T - ($375)' || item.registeredFor == 'Both - Acro & Tumbling plus All Levels - ($400)'"> Short Size <b>{{item.shortSize}}</b></div>           <div> T-shirt Size <b>{{item.teeSize}}</b></div>
          <v-btn class="approve-deny-btn" v-if="!item.approved" small dense elevation="0" color="success" @click="approveDeny(item)">Approve</v-btn>
          <v-btn class="approve-deny-btn" v-if="item.approved" small dense elevation="0" color="error" @click="approveDeny(item)">Deny</v-btn>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="showDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card v-if="this.athleteToBeDeleted">
        <v-card-title class="text-h5 text-center red--text text--darken-2 justify-center">
          <div>Are you sure you want to remove</div>
          <div class="black--text">{{this.athleteToBeDeleted.athleteFirstName}} {{this.athleteToBeDeleted.athleteLastName}}</div>
          <div>from the roster</div>
        </v-card-title>
        <v-card-text>This action cannot be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="deleteUser()"
            dark
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import api from "@/api/server-api";
export default {
  data() {
    return {
      registration: [],
      sendEmailToCoach: true,
      showDeleteDialog: false,
      athleteToBeDeleted: null,
    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.registration = (await api.post("/athlete", {keyValue: {coachUsagNumber: id}})).data;
  },
  methods: {
    incomplete(d){
      return !d.athleteGPA || !d.athleteYoutube || !d.AthleteInstagram || d.vt_current.length == 0 || d.vt_working.length == 0 || d.bb_current.length == 0 || d.bb_working.length == 0 || d.ub_current.length == 0 || d.ub_working.length == 0 || d.fx_current.length == 0 || d.fx_working.length == 0;
    },
    async approveDeny(a) {
      await api.post("/approveDenyAthlete", {athleteUsagNumber: a.athleteUsagNumber, approved: !a.approved, sendEmail: this.sendEmailToCoach});
      const {id} = this.$route.query;
      this.registration = (await api.post("/athlete", {keyValue: {coachUsagNumber: id}})).data;
    },
    async approveDenyAll(val) {
      const {id} = this.$route.query;
      this.registration = (await api.post("/approveDenyAll", {coachUsagNumber: id, approved: val, sendEmail: this.sendEmailToCoach})).data;
    },
    confirmDelete(athlete) {
      this.showDeleteDialog = true;
      this.athleteToBeDeleted = athlete;
    },
    async deleteUser() {
      await api.post("/deleteAthleteById", {id: this.athleteToBeDeleted._id});
      const {id} = this.$route.query;
      this.registration = (await api.post("/athlete", {keyValue: {coachUsagNumber: id}})).data;
      this.showDeleteDialog = false;
    },
  },

}
</script>

<style lang="scss">
.registration-details-header {
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}
.label {
    font-weight: bold;
    padding-top: 10px;
}
.athlete-row {
  border-bottom: 1px solid #CCC;
  padding-bottom: 15px;
  justify-content: space-between;
}
.approved, .not-approved {
  padding-left: 5px;
}
.approved {
  color:rgb(75, 128, 0);
}
.not-approved {
  color:rgb(128, 13, 0);
}
.athlete-row b {
  padding-right: 7px;
}
.small {
  font-size: 0.9rem;
}
.approve-deny-btn {
  min-width: 100px;
  width: 100px;
}
.approve-all-deny-all-btn {
  min-width: 130px;
  width: 130px;
}
.approve-all-container {
  flex-direction: row;
  align-items: center;
}
.approve-all-container button {
  margin-left: 7px;
}
.sendEmailToCoach {
  border: 1px solid #CCC;
  padding: 5px !important;
  border-radius: 3px;
  margin-bottom: 3px;
}
</style>