



























































































































































































































































































































import Vue from "vue";
import Coach from "../../components/Coach.vue";
export default Vue.extend({
  name: "Home",
  components: {
    Coach
  },
  data: () => ({
    
  }),
  computed: {

  },
  methods: {
  },
});
