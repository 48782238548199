<template>
  <div :class="`college-tile`">
  <div :class="`${confirmed === true ? 'confirmed' : ''}`"></div>
    <div class="college-logo">
      <img v-if="logo" :src="logo" :alt="collegeName">
      <img v-if="!logo" src="logo-placeholder.png" :alt="collegeName">
    </div>
    <div class="college-name">
      {{collegeName}}
    </div>
  </div>
</template>
<script>
export default {
  methods: {
  },
  name: 'collegeTile',
  props: {
    collegeName: {
      type: String,
      default: ''
    },
    confirmed: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss">
.college-tile {
  padding: 5px;
  border-radius: 2px;
  border: 1px solid #AAAAAA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  .college-logo{
    max-width: 100px;
    img {
      width: 100%;
    }
  }
  .college-name {
    text-align: center;
  }
  .confirmed {
    background-image: url("/confirmed-3.png");
    background-size: 130px;
    background-position: top right;
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    right: 0;
    margin: 0!important;
    padding:0 !important;
  }
}
</style>