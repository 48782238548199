<template>
  <v-container fluid class="outter">
      <simple-back-button/>
    <v-container class="page"  id="waiver-container">
      <div class="d-flex registration-details-header">
        <h1 class="success--text">Risk/Waiver of Liability</h1>
      </div>
      <h3 class="mb-6 mt-8  ">By signing up online I am agreeing to the following</h3>
      <p>
        <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[0]"/><b>{{this.athlete.athleteFirstName}} {{this.athlete.athleteLastName}}</b> has no physical or health conditions that would limit his or her participation in athletic activities or present a known and undue risk of transmitting any virus and/or disease to other participants in these activities.
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[1]"/>I/We hereby give permission for <b>{{this.athlete.athleteFirstName}} {{this.athlete.athleteLastName}}</b> to have her temperature taken before participation in activities at Apex Gymnastics Athletics; participate in activities Apex Gymnastics Athletics; and to work on all of the necessary equipment. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[2]"/>     
      I/We understand that Apex Gymnastics Athletics will keep confidential information regarding participants' temperatures and reserves the right to exclude individuals from participation in activities based on this information in accordance with its policies. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[3]"/>      I/We understand that Apex Gymnastics Athletics may inform other participants of any confirmed diagnosis of COVID-19 (or other transmittable virus/disease), to the extent they may have been exposed, but will maintain confidentiality to the extent possible; 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[4]"/>      
      I/we waive all privacy-related claims based on such disclosure(s). 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[5]"/>      
      I/We assume all risks and hazards incidental to the conduct of this activity and transportation to and from this activity. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[6]"/>      
      In case of emergency, the Apex Gymnastics Athletics, staff has my/our permission to use their judgment with regard to treatment until I/we can be contacted.
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[7]"/>      
        Moreover, I/we hereby authorize any qualified physician contacted to proceed with treatment. In case of emergency, I/we understand that my/our child/children will be transported to the nearest hospital OR (preferred hospital) by the local emergency resource if rescue squad deems necessary. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[8]"/>       
        I/We understand that I/we will be responsible for all medical and emergency transportation expenses. It is understood that in some medical situations, the staff will need to contact the emergency resources before contacting the parent or other adult acting on the parents' behalf. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[9]"/>       
        Warning.... Catastrophic injury, paralysis, or death can result from improper conduct of this activity. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[10]"/>       
        I/We agree and consent that participation is voluntary and at each individual's own risk. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[11]"/>       
        I/We acknowledge that participation entails known and unknown risks that may result in physical injury; the transmission of virus and/or disease; or other injury, loss, or death of any participant(s). 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[12]"/>       
        I/We understand that such risks simply cannot be eliminated. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[13]"/>       
        I/We knowingly, voluntarily, and expressly assume the risk of, and responsibility for, injury and damages. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[14]"/>       
        I/We specifically agree that the employees, owners, volunteers, and other agents of Apex Gymnastics Athletics ("the Released Parties") shall not be responsible for such injuries/damages, even if caused in whole or part by the negligence or fault of the Released Parties, whether such negligence is present at the signing of this Waiver or takes place in the future. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[15]"/>       
        This waiver and release does not apply to gross negligence or intentional torts by the Released Parties. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[16]"/>       
        To the extent allowed by applicable law, I/we agree that I/we will waive, release, discharge, covenant not to sue, and indemnify and hold harmless (from all damages and expenses, including attorney fees) the Released Parties from any and all claims for injury and damage that the child(ren) listed on this form suffer, even if the risk(s) arise out of the negligence or fault of the Released Parties.
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[17]"/>       
        By executing this Agreement, I/we agree that the Released Parties shall not be liable for any damages arising from personal injuries sustained by the child(ren) listed on this form as a result of any and all activities related to participation in activities at Apex Gymnastics Athletics. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[18]"/>       
        By signing, I/we expressly state that I/we have had sufficient opportunity to read and consider this entire Waiver and ask any questions associated with it; agree that 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[19]"/>       
        I/we have read and understood it and voluntarily agree to be bound by its terms; and acknowledge that this Waiver contains a waiver and release of claims. 
      </p>
      <p>
      <v-text-field hide-details  v-mask="{pattern:/[A-Z]{1,2}/}" class="initials" v-model="initials[20]"/>       
        I/We agree that if any portion of this Waiver is found to be void or unenforceable, the remaining portions shall remain in full force and effect.
      </p>  
      <v-row class="mt-16">
        <v-col cols="12" xs="12" sm="5" class="pt-0 pb-0">
          <v-text-field label="(Guardian) Enter Your Full Name" v-model="guardianFullName" placeholder="John Doe" outlined required :rules="[...rules.required]"/>
        </v-col>
        <v-col cols="12" xs="12" sm="3" class="pt-0 pb-0">
          <v-text-field label="Enter Today's Date" v-model="signingDate" v-mask="'##/##/####'" placeholder="MM/DD/YYYY" outlined required :rules="[...rules.required]"/>
        </v-col>
      </v-row>
      <survey :survey="survey" />
      <v-alert
        variant="tonal"
        close-label="Close Alert"
        color="deep-purple-accent-4"
        title="Closable Alert"
      >
        You must sign the waiver first before you can submit it!
      </v-alert>
    </v-container>
      <v-container class="justify-center d-flex mb-16">
        <v-btn color="success" size="x-large" class="p-16 sign-waiver-button" @click="saveDivAsImage">save</v-btn>
      </v-container>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
import { Model } from "survey-vue";
import html2canvas from 'html2canvas';
import "survey-vue/defaultV2.min.css";
import SimpleBackButton from "../../components/SimpleBackButton.vue"
const fs = require('fs');
const path = require('path');
const FormData = require('form-data');
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {SimpleBackButton},
  data() {
    const json = {
      "elements": [
        {
          "type": "signaturepad",
          "name": "signature",
          "title": "Please sign here",
          "isRequired": true,
          "storeDataAsText": false,
          "waitForUpload": true,
          "signatureWidth": 600,
          "penColor": "#2D4760"
        }
      ],
      "showQuestionNumbers": false,
       "showCompletedPage": false,
       "showCompleteButton": false,

    };
    const survey = new Model(json);
    survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });
    survey.onUploadFiles.add((_, options) => {
        const formData = new FormData();
        options.files.forEach((file) => {
            formData.append(file.name, file);
        });
    
        fetch("https://api.surveyjs.io/private/Surveys/uploadTempFiles", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                options.callback(
                    options.files.map((file) => {
                        return {
                            file: file,
                            content: "https://api.surveyjs.io/private/Surveys/getTempFile?name=" + data[file.name]
                        };
                    })
                );
            })
            .catch((error) => {
                console.error("Error: ", error);
                options.callback([], "An error occurred during the file upload.");
            });
    });
   
    
    async function deleteFile(fileURL, options) {
        try {
            const name = fileURL.split("=")[1];
            const apiUrl = `https://api.surveyjs.io/private/Surveys/deleteTempFile?name=${name}`;
            const response = await fetch(apiUrl, {
                method: "DELETE"
            });
    
            if (response.status === 200) {
                console.log(`File ${name} was deleted successfully`);
                options.callback("success");
            } else {
                console.error(`Failed to delete file: ${name}`);
                options.callback("error");
            }
        } catch (error) {
            console.error("Error while deleting file: ", error);
            options.callback("error");
        }
    }
    
    survey.onClearFiles.add(async (_, options) => {
        if (!options.value || options.value.length === 0)
            return options.callback("success");
        if (options.value) {
            await deleteFile(options.value, options);
        }
    });

    return {
      survey: survey,
      signingDate: '',
      guardianFullName: '',
      athlete: [],
      initials:[],
      rules: {
      name:[],
      required: [v => !!v || 'Required'],
      email: [
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      usagNumber: [],
      blankCanvas: null,
      showSignatureError: true,
    },
    }
  },
  async mounted() {
    const {id} = this.$route.query;
    this.athlete = (await api.post("/athlete", {keyValue: {_id: id}})).data?.data?.[0] || {};
    
    const div = document.querySelector("#waiver-container"); 
    html2canvas(div).then(canvas => {
      const c = canvas.toDataURL();
      this.blankCanvas = c;
    });
  },
  methods: {
    async saveDivAsImage() {
        const div = document.querySelector("#waiver-container"); 
       html2canvas(div).then(canvas => {
          const fileName = `${this.athlete.gymName}-${this.athlete.athleteFirstName} ${this.athlete.athleteLastName}`.replace(/\s/g, '_').toLowerCase();
          const c = canvas.toDataURL();
          fetch(c)
            .then(res => res.blob())
            .then(blob => {
              const fd = new FormData();
              const file = new File([blob], `${fileName}.png`);
              fd.append('file', file)
              fd.append("id", this.athlete._id);
              fd.append("date", this.signingDate);
              fd.append("guardian", this.guardianFullName);
              api.post("/uploadwaiver", fd).then(response => {
                console.log(response.data.data[0]._id, response.data.data[0])
                this.$router.push(`/regDetails?id=${response.data.data[0]._id}`)
              });
            });

              
          // document.body.appendChild(canvas)
      });
    },
    convertBase64ToMultipart(base64Str, filename) {
      // Convert base64 to Buffer
      const base64Data = base64Str.replace(/^data:image\/\w+;base64,/, '');
      const buffer = Buffer.from(base64Data, 'base64');

      // Create a FormData instance
      const formData = new FormData();

      // Create a fake path for the image (this is required for the form data)
      const filePath = path.join(__dirname, filename);

      // Write the buffer to a file to simulate a file upload (necessary step if using certain modules like 'request')
      fs.writeFileSync(filePath, buffer);

      // Append the form data with the file
      formData.append('file', fs.createReadStream(filePath), filename);

      return formData;
    }
  }
}
</script>

<style lang="scss">
.outter {
  background-color: #EFEFEF;;
}
.page {
  background-color: #FFF;
  border: 1px solid #CCC;
  border-right: 1px solid #CCC;
  padding: 1.5rem;
  margin-top: 1rem;
}
.initials {
    border: 1px solid #870000;
    margin-right: 1rem;
    width: 36px;
    border-radius: 4px;
    display: inline-block;
    padding: 0;
    input { 
      border:none;
      padding: .5rem;
      text-align: center;
      margin: 0;
      text-transform: uppercase;
    }
}
.sd-root-modern{
  background-color: #FFF;
}
.sd-element--with-frame {
  border: none !important;
  box-shadow: none;
}
.registration-details-header {
  justify-content: space-between;
  vertical-align: middle;
  align-content: center;
  align-items: center;
}
.small {
  font-size: 0.9rem;
}

#sv-nav-complete{
  display: none;
}

.sign-waiver-button {
  width: 300px;
  padding: 16px !important;
  height: 50px !important;
}

</style>