<template>
  <div>
    <v-row class="small label mt-0 mb-0 pb-0 pt-0">
      <v-col class="small  mt-0 mb-0 pb-0 pt-0">
      {{this.label}}
      </v-col>
    </v-row>
    <v-row class="text  mt-0 mb-0 pb-0 pt-0">
      <v-col class="small  mt-0 mb-0 pb-0 pt-0">
      {{this.text}}
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  methods: {
  },
  name: 'LabelDataField',
  props: {
    label: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '',
    },
  }
}
</script>
<style scoped lang="scss">
  .label{
    color:rgb(33, 33, 117);
    font-weight:bold !important;
    
  }
</style>