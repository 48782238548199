<template>
  <div>
    <div class="full-width">
      <h3>{{label}}</h3>
      <div class="mt-2">
        <p class="small text-grey" v-if="this.list.length == 0">This athlete has not added any skills for <b>{{this.label}}</b> yet.</p>
        <div class="skill-entry" v-for="item, index in this.list" :key="index">
          {{item}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
  },
  name: 'Alert',
  props: {
    label: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },    
    onAdd: {
      type: Function,
      default: () =>null,
    },
    onDelete: {
      type: Function,
      default: () =>null,
    },
    value:{},
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.skill-entry {
  border: 1px solid #3548d4;
  padding: 0.5rem;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  border-left-width: 5px;
}
</style>