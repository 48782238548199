<template>
  <div :class="`section-row coach-title ${gray ? 'gray' : ''}`">
    <div class="section-col one-third pr-5 photo-col">
        <img class="image" :src="photo"/>
        <div class="coach-name pb-5">{{name}}</div>
        <h3>{{role}}</h3>
        <p>{{from}}</p>
    </div>
    <div class="section-col two-thirds px-5 text-left">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Coach',
  data: () => ({

  }),
  props: {
    name: {
      type: String,
      default: '',
    },
    photo: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    gray: {
      type: Boolean,
      default: false,
    }
  },
  methods:{},

}
</script>
<style lang="scss">
.coach-title {
      padding: 2rem;
  &.gray {
    background-color: #f5f5f5;
  }
  .image {
    max-width: 200px;
  }
  .photo-col {
    max-width: 350px;
    text-align: center;
  }
  .coach-name {
    font-weight: 700;
    font-size: 2rem;
  }
}
</style>