<template>
  <v-container class="px-10">
      <h3 class="full-width">You can make your {{this.typeOfPayment}} by sending us a check or via VENMO</h3>
      <div class="full-width text-center">
       <v-row class="mt-8">
        <v-col>
          <h2 class="mb-3">BANK CHECK</h2>
          <p>Please write a check out to <b>Apex Gymnastics Athletics</b></p>
          <p>in the amount of<b class="amount">  US$ {{this.checkAmount}}</b></p>
          
          <p>
            Mail the check to: <br/>
            <b>7 Flowerfield, suite 14<br/>
            Saint James, NY, 11780</b>
          </p>

          <p>
            In the memo section write the following:<br/>
            <b> {{camp}}</b>
          </p>
        </v-col>
        <v-divider vertical v-if="!isMobile()"/>
        <v-col>
          <h2 class="mb-3">VENMO</h2>
          <p>For VENMO payments, an additional fee will be charged per transaction</p>
          <p> 
            Search for <b>ApexGymnasticsAthletics</b> and submit a payment<br/>
            in the amount of <b>US$ {{this.venmoAmount}}</b>
          </p>
          <p>
            In the memo section write the following:<br/>
            <b> {{camp}}</b>
          </p>   

          <v-btn href="https://account.venmo.com/u/ApexGymnasticsAthletics" color="info" target="_blank"> Click here to open up VENMO </v-btn>
        </v-col>
       </v-row>
      </div>
  </v-container>
</template>
<script>
/* eslint @typescript-eslint/no-var-requires: "off" */
import api from "@/api/server-api";
// https://surveyjs.io/form-library/examples/upload-signature-pad-data-to-server/vuejs#content-code
export default {
  components: {},
  data() {
    return {
      athlete: [],
    }
  },
  props: {
    typeOfPayment: {
      type: String,
      default: 'payment'
    },
    checkAmount: {
      type: String,
      default: '0.00'
    },
    venmoAmount: {
      type: String,
      default: '0.00'
    },
    camp: {
      type: String,
      default: 'College Showcase 2024 - name of athlete'
    }
  },

  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {

  },
}
</script>

<style lang="scss">


</style>